import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import './utils/wechatTrim' // 去掉 url 微信返回参数
//import './utils/baiduStatistics'
import 'amfe-flexible/index' // 适配： px/2
import './utils/onload'

import './styles/index.css' // 全局样式
import 'pagoda-mobile/lib/toast/style' // toast 样式
import 'pagoda-mobile/lib/dialog/style' // dialog 样式
import 'pagoda-mobile/lib/swipe/style' // swipe样式
import 'pagoda-mobile/lib/uploader/style'
import 'pagoda-mobile/lib/icon/style'
import 'pagoda-mobile/lib/image-preview/style' // ImagePreview样式
import 'pagoda-mobile/lib/swipe-cell/style'
import 'pagoda-mobile/lib/swipe-item/style'
import 'pagoda-mobile/lib/rate/style' // rate样式
import 'pagoda-mobile/lib/count-down/style' // countDown 样式
import 'pagoda-mobile/lib/loading/style' // loading 样式
import 'pagoda-mobile/lib/tabbar/style'
import 'pagoda-mobile/lib/tabbar-item/style'
import 'swiper/swiper-bundle.css'
import 'pagoda-mobile/lib/tabs/style'
import 'pagoda-mobile/lib/tab/style'
import './utils/uni.webview.1.5.4.js'
// import 'pagoda-mobile/lib/form/style'

import $http from '@/api' // 网络请求
import * as filters from './filters' // 过滤器
import VueTouch from 'vue-touch' // 触屏:  上拉加载更多
import { Lazyload } from 'pagoda-mobile' // 图片懒加载
import VueAwesomeSwiper from 'vue-awesome-swiper'
import wx from '@pagoda-tools/jweixin'
import moment from 'moment' //导入文件

import VueWechatTitle from 'vue-wechat-title'
import { Tabbar, TabbarItem } from 'pagoda-mobile'
import { Swipe, SwipeItem } from 'pagoda-mobile'
import { Uploader } from 'pagoda-mobile'
import { Icon } from 'pagoda-mobile'
import { ImagePreview } from 'pagoda-mobile'
import { Rate } from 'pagoda-mobile'
import { CountDown } from 'pagoda-mobile'
import { Loading } from 'pagoda-mobile'
import { Tab, Tabs } from 'pagoda-mobile'
import { vueBaberrage } from 'vue-baberrage'
import 'animate.css/animate.min.css'
import Vconsole from 'vconsole'
import { phoneEncryption, nickNameEncryption } from '@/filters/index'

import Swiper2, { Navigation, Pagination, Autoplay } from 'swiper'
Swiper2.use([Autoplay, Navigation, Pagination])

Vue.use(VueAwesomeSwiper)

if (
  process.env.VUE_APP_ENV !== 'prod' &&
  process.env.NODE_ENV === 'production'
) {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}

Vue.config.productionTip = false

Vue.prototype.$http = $http
Vue.prototype.wx = wx
Vue.prototype.$moment = moment //赋值使用
moment.locale('zh-cn') //需要汉化

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(Lazyload)
Vue.use(VueWechatTitle)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Uploader)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)

Vue.use(ImagePreview)
Vue.use(Rate)
Vue.use(CountDown)
Vue.use(Loading)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(vueBaberrage)
Vue.config.ignoredElements = ['wx-open-launch-weapp', 'wx-open-launch-weapp']
Vue.prototype.uploadFileURL =
  process.env.VUE_APP_ENV === 'prod'
    ? 'https://fastdfs-download.pagoda.com.cn'
    : 'https://fastdfs-download.test.pagoda.com.cn'

Vue.prototype.$phoneEncryption = phoneEncryption
Vue.prototype.$nickNameEncryption = nickNameEncryption

//弹出框禁止滑动--弹出弹窗时调用
Vue.prototype.stopScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false) // 禁止页面滑动
}
//弹出框可以滑动--关闭弹窗时调用
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = '' // 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
