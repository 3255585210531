export default [
    {
      path: '/anniversaryGift/index',
      meta:{
        title: '心享周年庆',
      },
      component: (resolve) => require(['@/pages/anniversaryGift/index'], resolve)
    },
    {
        path: '/anniversaryGift/message',
        meta:{
          title: '心享周年庆',
        },
        component: (resolve) => require(['@/pages/anniversaryGift/leaveMessage'], resolve)
      },
  ]
  