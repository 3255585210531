export default [
    {
        path: '/integral/record',
        meta:{
            title:'积分记录',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/integral/record'], resolve)
    },
    {
        path: '/integral/rules',
        meta:{
            title:'积分规则说明',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/integral/rules'], resolve)
    }
]