export default [
    {
      path: '/lef/index',
      meta:{
        title: '527爱吃节'
      },
      component: (resolve) => require(['@/pages/loveEatFestival/index'], resolve)
    },
    {
      path: '/lef/guide',
      meta:{
        title: '527爱吃节'
      },
      component: (resolve) => require(['@/pages/loveEatFestival/guide'], resolve)
    },
    
  ]