import $axios from '@/utils/http'
import Base from './base'

export default class loveEat extends Base {
  // 基础信息
  getInit (params ) {
    return $axios.post(`${this.baseURL}/superVipOpen/info`, params)
  }
  // 创建订单
  createOrder (params ) {
    return $axios.post(`${this.baseURL}/superVipOpen/activity`, params)
  }
  // 积分列表
  getRecordList (params ) {
    return $axios.post(`${this.baseURL}/superVipOpen/integralList`, params)
  }
  // 分享
  setShare (params ) {
    return $axios.post(`${this.baseURL}/superVipOpen/share`, params)
  }
  
} 