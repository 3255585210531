import $axios from '@/utils/http'
import Base from './base'

export default class Login extends Base {
  //  获取验证码
  getCode ( params ) {
    return $axios.post(`${this.baseURL}/customer/captchaCode`, params)
  }
  login ( params ) {
    return $axios.post(`${this.baseURL}/customer/login`, params)
  }
  //外部登录并发券（异业合作-广州周年庆）
  webLogin ( params ) {
    return $axios.post(`${this.baseURL}/login/outer`, params)
  }
  // web登陆 serverless (返回token)
  loginWeb ( params ) {
    return $axios.post(`${this.baseURL}/electronic/loginPufa`, params)
  }

}
