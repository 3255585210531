import $axios from '@/utils/http'
import Base from './base'

export default class Invite extends Base {
  // 获取奖励配件( 首页数据 ) 
  getAwardsConfigure ( params ) {
    return $axios.post(`${this.baseURL}/invite/awardsConfigure`, params)
  }
  // 获取排行榜
  getRankingList ( params ) {
    return $axios.post(`${this.baseURL}/invite/cycleRankList`, params)
  }
  // 获取我的邀请记录
  getInviteRecords ( params ) {
    return $axios.post(`${this.baseURL}/invite/inviteInfo`, params)
  }
  // 获取我的获奖记录
  getAwardRecords ( params ) {
    return $axios.post(`${this.baseURL}/invite/awardRecords`, params)
  }
  // 获取近期获奖记录
  getRecentRecordOfAwards ( params ) {
    return $axios.post(`${this.baseURL}/invite/recentRecordOfAwards`, params)
  }
  // 获取建立关系
  getBuildRelation ( params ) {
    return $axios.post(`${this.baseURL}/invite/buildRelation`, params)
  }
  // 获取推荐商品
  getRecommendGoods ( params ) {
    return $axios.post(`${this.baseURL}/homepage/recommendGoods`, params)
  }
  // 获取分享发券
  getShareCoupon ( params ) {
    return $axios.post(`${this.baseURL}/invite/shareCoupon`, params)
  }
  // 获取广告数据
  getBroadcasts ( params ) {
    return $axios.post(`${this.baseURL}/invite/activityNotice`, params)
  }
  //
  getInviteShareInfo ( params ){
    return $axios.post(`${this.baseURL}/invite/shareInfo`, params)
  }
  //分页获取邀请数据
  getinviteRecordsPage ( params ){
    return $axios.post(`${this.baseURL}/invite/infoPage`, params)
  }
}
