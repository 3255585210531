function getCookie() {
  let cookie = {}
  let all = document.cookie
  if (all === "") return cookie
  let list = all.split("; ")
  for (let i = 0; i < list.length; i++) {
    let item  = list[i]
    let p = item.indexOf("=")
    let name = item.substring(0, p)
    let value = item.substring(p + 1)
    value = decodeURIComponent(value)
    cookie[name] = value
  }
  return cookie
}

export default {
  setItem (key, value, maxage, path, domain, secure) { // maxage 秒
    path = path || '/'
    let item = key + "=" + encodeURIComponent(value)
    if (maxage) item += "; max-age=" + maxage
    if (path) item += "; path=" + path
    if (domain) item += "; domain=" + domain
    if (secure) item += "; secure=" + secure
    document.cookie = item
  },
  getItem (name) {
    let cookie = getCookie()
    return cookie[name] || null
  },
  clear() {
    var keys = document.cookie.match(/[^ =;]+(?==)/g);
    if(keys) {
      for(var i = keys.length; i--;)
        document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
    }
  }
}