export default [
    {
      path: '/community/index',
      meta:{
        title: '熊猫大鲜'
      },
      component: (resolve) => require(['@/pages/community/index'], resolve)
    },
    {
        path: '/community/pullNew',
        meta:{
          title: '熊猫大鲜'
        },
        component: (resolve) => require(['@/pages/community/pullNew'], resolve)
      },
      // 社群拉新
      {
        path: '/h5/community',
        meta:{
            title:'百果园'
        },
        // component: (resolve) => require(['@/pages/community/community'], resolve)
        component: (resolve) => require(['@/pages/community/community_v2.0'], resolve)
      },
  ]