import $axios from '@/utils/http'
import Base from './base'

export default class member extends Base {
    // 获取心享会员信息
    getSuperVipInfo ( params ) {
        return $axios.post(`${this.baseURL}/superVip/newInfo`, params)
        // return $axios.post(`${this.baseURL}/superVip/info`, params)
    }
    // 获取心享会员配置
    getSuperVipConfig ( params ) {
        return $axios.post(`${this.baseURL}/superVip/config`, params)
    }
    // 获取心享会员权益
    getSuperVipPrivilege ( params ) {
        return $axios.post(`${this.baseURL}/superVip/privilege`, params)
    }
    // 获取心享会员商品列表
    getSuperVipGoodsList ( params ) {
        return $axios.post(`${this.baseURL}/superVip/goodsList`, params)
    }
    // 获取心享banner页面
    getSuperVipActivity ( params ) {
        return $axios.post(`${this.baseURL}/superVip/activity`, params)
    }
    // 心享会员业务邀请试用兑换
    getSuperVipMarketInvite ( params ) {
        return $axios.post(`${this.baseURL}/superVip/marketInvite`, params)
    }
    // 心享会员 试用兑换
    superVipTrialExchange ( params ) {
        return $axios.post(`${this.baseURL}/superVip/trialExchange`, params)
    }
    // 心享会员自动续费信息
    getSuperVipAutoRenew ( params ) {
        return $axios.post(`${this.baseURL}/superVip/autoRenew`, params)
    }
    // 心享会员已返积分记录
    getSuperVipReturnIntegralRecord ( params ) {
        return $axios.post(`${this.baseURL}/superVip/returnIntegralRecord`, params)
    }
    // 领取月度礼包
    superVipReceiveCouponBag ( params ) {
        return $axios.post(`${this.baseURL}/superVip/receiveCouponBag`, params)
    }
    // 实名认证
    setSuperVipIdentity ( params ) {
        return $axios.post(`${this.baseURL}/superVip/identity`, params)
    }
    // 检测用户信息完善情况
    getCompleteStatus ( params ) {
        return $axios.post(`${this.baseURL}/superVip/completeStatus`, params)
    }
    // 更新用户信息
    setUserInfo ( params ) {
        return $axios.post(`${this.baseURL}/customer/updateInfo`, params)
    }
    // 心享 邀请名单
    getSuperVipInviteRecord ( params ) {
        return $axios.post(`${this.baseURL}/superVip/inviteRecord`, params)
    }
    // 心享 城市是否支持
    checkSuperVipCitySupport ( params ) {
        return $axios.post(`${this.baseURL}/superVip/citySupport`, params)
    }
    //  会员页面 getBalance 获取钱包余额
    getBalance ( params ) {
        return $axios.post(`${this.baseURL}/customer/getBalance`, params)
    }
    //  心享订单 开通状态查询
    getSuperVipOpenCheckPayStatus ( params ) {
        return $axios.post(`${this.baseURL}/superVip/openCheckPayStatus`, params)
    }
    // 心享 签约状态
    getSuperVipCheckContractStatus ( params ) {
        return $axios.post(`${this.baseURL}/superVip/checkContractStatus`, params)
    }
    // 心享 开通生成订单
    getSuperVipCreateOrder ( params ) {
        return $axios.post(`${this.baseURL}/superVip/createOrder`, params)
    }
    // 
    payByEA ( params ) {
        return $axios.post(`${this.baseURL}/pay/payByEA`, params)
    }
    // 统一支付
    memberPay ( params ) {
        return $axios.post(`${this.baseURL}/pay/pay`, params)
    }
    // 心享 开通生成支付单 小程序
    createOrderWxMini ( params ) {
        return $axios.post(`${this.baseURL}/superVip/createOrderWxMini`, params)
    }
    // 心享 开通生成签约单 小程序
    createContractWxMini ( params ) {
        return $axios.post(`${this.baseURL}/superVip/createContractWxMini`, params)
    }
    // 心享解 代扣款解约请求-所有第三方解约渠道
    deleteContractRequest ( params ) {
        return $axios.postCrypto(`${this.baseURL}/superVip/deleteContractRequest`, params)
    }
    // 获取城市列表
    getCityList ( params ) {
        return $axios.post(`${this.baseURL}/cityManager/list`, params)
    }
    // 搜索门店及周边附近门店
    getSearchListStore ( params ) {
        return $axios.post(`${this.baseURL}/store/searchListStore`, params)
    }
    // 获取用户在城市下常用门店
    getListFrequentlyStore ( params ) {
        return $axios.post(`${this.baseURL}/store/listFrequentlyStore`, params)
    }
    // 获取月度礼包套餐列表
    getMonthGiftPackageList ( params ) {
        return $axios.post(`${this.baseURL}/superVip/monthGiftPackage/list`, params)
    }
    // 获取月度礼包套餐详情
    getMonthGiftPackageDetail ( params ) {
        return $axios.post(`${this.baseURL}/superVip/monthGiftPackage/detail`, params)
    }
    // 月度礼包套餐选择确认
    submitGiftPackage ( params ) {
        return $axios.post(`${this.baseURL}/superVip/monthGiftPackage/Submit`, params)
    }
    // 获取历史月度礼包列表
    getHistoryPackageList ( params ) {
        return $axios.post(`${this.baseURL}/superVip/monthGiftPackage/historyPackage`, params)
    }
    //-----------------------------v2.0-----------------------------------------------
    // 获取百果心享会员过期状态
    getOverdueInfo( params ){
        return $axios.post(`${this.baseURL}/superVip/overdueInfo`, params)
    }
    // 首页弹框提示
    getIndexToastMessage ( params ) {
        return $axios.post(`${this.baseURL}/superVip/customer/message`, params)
    }
    // 获取百果心享会员会员页次日达商品列表
    getVipVipGoodsList ( params ){
        return $axios.post(`${this.baseURL}/superVip/vipVipGoods`, params)
    }
    // 获取百果心享会员省钱总金额
    getSaveMoneyTotal ( params ) {
        return $axios.post(`${this.baseURL}/superVip/saveMoney/totalInfo`, params)
    }
    // 获取百果心享会员省钱总金额(分布图)
    getSaveMoneyDistributionMap ( params ) {
        return $axios.post(`${this.baseURL}/superVip/saveMoney/distributionMap`, params)
    }
    // 获取百果心享会员付费周期列表
    getPayPeriod ( params ) {
        return $axios.post(`${this.baseURL}/superVip/payPeriod`, params)
    }
    // 获取百果心享会员节省详细分页
    getPeriodDetailList ( params ) {
        return $axios.post(`${this.baseURL}/superVip/periodDetailList`, params)
    }
    // 获取百果心享购买类型banner
    getPayBanner ( params ) {
        return $axios.post(`${this.baseURL}/superVip/payBanner`, params)
    }

    // 首页banner
    getSuperVipBanner ( params ) {
        return $axios.post(`${this.baseURL}/superVip/banner`, params)
    }
    // 获取心享会员配置 v3
    // getMemberVipConfig ( params ) {
    //     return $axios.post(`${this.baseURL}/v3/superVip/config`, params)
    // }
    // 获取配置
    getCanSalesList ( params ) {
        return $axios.post(`${this.baseURL}/superVip/getCanSalesList`, params)
    } 
    // 积分好礼
    integralBanner ( params ) {
        return $axios.post(`${this.baseURL}/superVip/integralBanner`, params)
    }
    // 获取会员是否选定套餐信息
    getSelectPackageInfo ( params ) {
        return $axios.post(`${this.baseURL}/superVip/selectPackageInfo`, params)
    }
}
