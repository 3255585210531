export default [
  {
    path: '/surpriseeveryday/index',
    meta:{
      title: '百果园'
    },
    component: (resolve) => require(['@/pages/surpriseEveryDay/index'], resolve)
  },
  {
    path: '/surpriseeveryday/config',
    meta:{
      title: '百果园'
    },
    component: (resolve) => require(['@/pages/surpriseEveryDay/Config'], resolve)
  },
  {
    path: '/surpriseeveryday/share/:storeCode',
    meta:{
      title: '百果园'
    },
    component: (resolve) => require(['@/pages/surpriseEveryDay/Share'], resolve)
  }
]
