import $axios from '@/utils/http'
import Base from './base'

export default class Wednesday extends Base {
  // 获取是否领券 
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/wednesday/init`, params)
  }
  //获取积分
  getBalance ( params ) {
    return $axios.post(`${this.baseURL}/customer/getBalance`, params)
  }
  //领券
  getCoupon ( params ) {
    return $axios.post(`${this.baseURL}/wednesday/receive`, params)
  }
  //获取推荐商品
  getRecommendGoods ( params ) {
    return $axios.post(`${this.baseURL}/homepage/recommendGoods`, params)
  }
  // 获取配置的商品
  getGoodsList (params ) {
    return $axios.post(`${this.baseURL}/goods/configInfo`, params)
  }
} 