import $axios from '@/utils/http'
import Base from './base'

export default class memberHoliday extends Base {
  // 接口 抽奖
  luckyDraw ( params ) {
    return $axios.postCrypto(`${this.baseURL}/membershipDay/luck`, params)
  }
  //抽奖记录
  getDrawRecord ( params ) {
    return $axios.post(`${this.baseURL}/membershipDay/record`, params)
  }
  // 分享
  getShareInfo ( params ) {
    return $axios.post(`${this.baseURL}/membershipDay/share`, params)
  }
  // 获取投票人list 搜索 查看更多
  getVoteList ( params ) {
    return $axios.post(`${this.baseURL}/membershipDay/list`, params)
  }
  // 投票
  vote ( params ) {
    return $axios.postCrypto(`${this.baseURL}/membershipDay/vote`, params)
  }
  // 根据门店编码获取门店信息
  getStoreInfo ( params ) {
    return $axios.post(`${this.baseURL}/membershipDay/storeInfo`, params)
  }
  // 提交信息
  saveInfo ( params ) {
    return $axios.post(`${this.baseURL}/membershipDay/saveInfo`, params)
  }
} 