export default [
    {
        path: '/electronic/activity',
         meta:{
            title: '先享后付开心享'
        },
        component: (resolve) => require(['@/pages/electronic/activity'], resolve)
    },
    {
        path: '/electronic/index',
         meta:{
            title: '申请开通电子账户',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/index'], resolve)
    },
    {
        path: '/electronic/openElectronic',
         meta:{
            title: '申请开通电子账户',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/openElectronic'], resolve)
    },
    {
        path: '/electronic/memberBuy',
         meta:{
            title: '开通心享会员'
        },
        component: (resolve) => require(['@/pages/electronic/memberBuy'], resolve)
    },
    {
        path: '/electronic/loading',
         meta:{
            title: '支付订单',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/loading'], resolve)
    },
    {
        path: '/electronic/quickPayment',
         meta:{
            title: '签约快捷支付',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/quickPayment'], resolve)
    },
    {
        path: '/electronic/walletActivity',
         meta:{
            title: '百果园&浦发银行电子钱包专享活动',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/walletActivity'], resolve)
    },
    {
        path: '/electronic/recharge',
         meta:{
            title: '百果园&浦发银行专享充值',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/recharge'], resolve)
    },
    {
        path: '/electronic/agreementList',
         meta:{
            title: '协议列表',
            isWeb:true
        },
        component: (resolve) => require(['@/pages/electronic/agreementList'], resolve)
    },

    
  ]
  