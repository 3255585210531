import $axios from '@/utils/http'
import Base from './base'

export default class Wechat extends Base {
  // 获取微信网页授权跳转地址
  getWeChatGatewayUrl ( params ) {
    return $axios.post(`${this.baseURL}/weChatGateway/url`, params)
  }
  // 获取微信 jsApi 授权
  getWeChatJsApiAuth ( params ) {
    return $axios.post(`${this.baseURL}/wx/jsApiAuth`, params)
  }
  // 获取微信 token
  getAccessToken ( params ) {
    return $axios.post(`${this.baseURL}/customer/isBindPhone`, params)
  }
  // 获取定位信息
  getLocation ( params ) {
    return $axios.post(`${this.baseURL}/store/checkCity`, params)
  }
  // 同步微信信息
  syncWx ( params ) {
    return $axios.post(`${this.baseURL}/invite/syncWx`, params)
  }
  // shareUrlSave(params){
  //     return $axios.post(`${this.baseURL}/shareUrl/save`, params)
  // }
}
