export default [
    {
      path: '/fruitsDayMini/index',
      meta:{
        title: '周二国民水果日'
      },
//        component: (resolve) => require(['@/pages/anniversary/NJ'], resolve)

        component: (resolve) => require(['@/pages/fruitsDayMini/index_nationalFruitDay_h5'], resolve)
    },
    {
      path: '/nationalFruitDay/live',
      meta:{
        title: '周二国民水果日'
      },
      component: (resolve) => require(['@/pages/fruitsDayMini/index_nationalFruitDay_live'], resolve)
    },
    {
        path: '/nationalFruitDay/index',
        meta:{
            title: '周二国民水果日'
        },
//        component: (resolve) => require(['@/pages/anniversary/NJ'], resolve)

        component: (resolve) => require(['@/pages/fruitsDayMini/index_nationalFruitDay_h5'], resolve)
    },
  ]
  