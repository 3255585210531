export default [
  {
    path: '/tryEat/apply/:activityCode/:integral',
    meta:{
        title:'申请试吃'
    },
    component: (resolve) => require(['@/pages/tryEat/apply'], resolve)
  },
  {
    path: '/tryEat/uploadReport/:activityCode/:orderTradeNo',
    meta:{
        title:'填写报告'
    },
    component: (resolve) => require(['@/pages/tryEat/uploadReport'], resolve)
  },
  {
    path: '/tryEat/reportDetails/:orderTradeNo',
    meta:{
        title:'报告详情'
    },
    component: (resolve) => require(['@/pages/tryEat/reportDetails'], resolve)
  },
  //兼容安卓链接配置错误
  {
    path: '/tryEat/reportDetails',
    meta:{
        title:'报告详情'
    },
    component: (resolve) => require(['@/pages/tryEat/reportDetails'], resolve)
  },
  {
    path: '/tryEat/accusation/:type',
    meta:{
        title:'举报'
    },
    component: (resolve) => require(['@/pages/tryEat/accusation'], resolve)
  },
  {
    path: '/tryEat/reportDetailsShare/:reportID',
    meta:{
        title:'报告详情分享',
        filterateApi:true
    },
    component: (resolve) => require(['@/pages/tryEat/reportDetailsShare'], resolve)
  },
  {
    path: '/tryEat/index',
    meta:{
      title: '试吃中心'
    },
    // component: (resolve) => require(['@/pages/tryEat/index'], resolve)
    component: (resolve) => require(['@/pages/tryEat/index_v2.vue'], resolve)
  },
  {
    path: '/tryEat/my',
    meta:{
      title: '我的试吃'
    },
    // component: (resolve) => require(['@/pages/tryEat/my'], resolve)
    component: (resolve) => require(['@/pages/tryEat/my_v2'], resolve)
  },
  {
    path: '/tryEat/report',
    meta:{
      title: '往期报告'
    },
    // component: (resolve) => require(['@/pages/tryEat/report'], resolve)
    component: (resolve) => require(['@/pages/tryEat/report_v2'], resolve)
  },
  {
    path: '/tryEat/activityDetails/:actCode', 
    meta:{
      title: '试吃详情'
    },
    // component: (resolve) => require(['@/pages/tryEat/activityDetails'], resolve)
    component: (resolve) => require(['@/pages/tryEat/activityDetails_v2'], resolve)
  },
  {
    path: '/tryEat/activityShare/:actCode',
    meta:{
      title: '商品分享',
      filterateApi:true,
    },
    component: (resolve) => require(['@/pages/tryEat/activityShare'], resolve)
  },
  {
    path: '/tryEat/reportStencil',
    meta:{
      title: '报告模版'
    },
    component: (resolve) => require(['@/pages/tryEat/reportStencil'], resolve)
  },
  {
    path: '/tryEat/strategy',
    meta:{
      title: '试吃攻略'
    },
    component: (resolve) => require(['@/pages/tryEat/strategy'], resolve)
  },
  {
    path: '/tryEat/applyResult/:applyResult',
    meta:{
      title: ''
    },
    component: (resolve) => require(['@/pages/tryEat/applyResult'], resolve)
  },
  {
    path: '/tryEat/uploadReport/success',
    meta:{
      title: '完成报告'
    },
    component: (resolve) => require(['@/pages/tryEat/uploadReportSuccess'], resolve)
  },
  {
    path: '/tryEat/indexShare',
    meta:{
      title: '试吃中心分享',
      filterateApi:true
    },
    component: (resolve) => require(['@/pages/tryEat/indexShare'], resolve)
  },
]
  
