export default [
    {
        path: '/web/coupon/:activityID',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/web/coupon'], resolve)
    },
    {
        path: '/FCJoin',
        meta:{
            title:'盛果记',
            web: true,
        },
        component: (resolve) => require(['@/pages/web/FCJoin/FCJoin'], resolve)
    },
    {
        path: '/FCJoin/submit',
        meta:{
            title:'盛果记',
            web: true,
        },
        component: (resolve) => require(['@/pages/web/FCJoin/submit'], resolve)
    },
    {
        path: '/activity/:imageName/:imageType',
        meta:{
            title:'百果园',
            web: true,
        },
        component: (resolve) => require(['@/pages/web/activityImage'], resolve)
    },
  ]
  