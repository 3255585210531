import $axios from '@/utils/http'
import Base from './base'

export default class activityH5 extends Base {
  // ---------------------异业合作（移动）Start---------------------------
  //  获取验证码
  getCode ( params ) {
    return $axios.post(`${this.baseURL}/captcha/send`, params)
  }
  //外部登录并发券（异业合作-北京 移动合作登录发券）
  webLogin ( params ) {
    return $axios.post(`${this.baseURL}/bjyd/ydLogin`, params)
  }
  // ---------------------异业合作（移动）End--------------------------- 

}
