import $axios from '@/utils/http'
import Base from './base'

export default class Receive extends Base {
  // -------周二水果日 start------------------
  // 获取页面初始信息 
  getInit (params ) {
    return $axios.post(`${this.baseURL}/h5Activity/vdIndex`, params)
  }
  // 领券
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/h5Activity/vdGetCoupon`, params)
  }
  // 获取页面图片配置
  getImgConfig ( params ) {
    return $axios.post(`${this.baseURL}/h5Activity/vdIntegralMall`, params)
  }
  // -------周二水果日 end--------------------
  
  // -------国民水果日 start------------------
  // 国民水果日配置信息
  nationalFruitDayConfig ( params ) {
    return $axios.postCrypto(`${this.baseURL}/nationalFruitDay/config`, params)
  }
  // 国民水果日 领取
  nationalFruitDayReceive ( params ){
    return $axios.postCrypto(`${this.baseURL}/nationalFruitDay/receive`, params)
  }
  // -------国民水果日 end--------------------
}
