import $axios from '@/utils/http'
import Base from './base'

export default class signIn extends Base {
  // 签到
  sign ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/sign`, params)
  }
  // 获取签到配置及获取该用户的签到基础信息
  getSignConfig (params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/detail`, params)
  }
  // 旧版兑换值及弹框 兑换模块展示
  getOldExchangeValueInfo ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/exchangeInfo`, params)
  }
  // 签到提醒状态变更
  setRemind ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/settingRemind`, params)
  }
  // 旧版兑换值转换积分
  exchangeScore ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/exchangeValueChange`, params)
  }
  // 积分兑换优惠券
  exchangeCoupon ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/exchangeCoupon`, params)
  }
  // 兑换值弹框一次提示
  cancelExchangeModal ( params ) {
    return $axios.postCrypto(`${this.baseURL}/signIn/exchangeInfoTips`, params)
  }
  // 查看更多
  jump ( params ) {
    return $axios.post(`${this.baseURL}/duiba/jump`, params)
  }
  // 领取次日达积分
  receiveNextDayIntegral (params) {
    return $axios.postCrypto(`${this.baseURL}/signIn/receiveNextDayIntegral`, params)
  }
} 