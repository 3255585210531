import axios from 'axios'
import helper from './helper'
import Crypto from './crypto'
import cookieStorage from '@/utils/cookieStorage'

// 创建实例时修改配置
const service = axios.create({
  // 请求超时时间（毫秒）
  timeout: 15000,
  responseType:'json'
})

// 添加一个请求拦截器
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    config.headers['content-type'] = 'application/json'

    let tempObj = {}
    tempObj.openId = cookieStorage.getItem('openId') || ''
    tempObj.notes = sessionStorage.getItem('notes') || ''
    if(window.appEnv === 'miniProgram') {
      config.headers['userToken'] = sessionStorage.getItem('user_token') || ''
      config.headers['customerID'] = sessionStorage.getItem('user_id') || ''
      tempObj.channel = sessionStorage.getItem('channel') || 'h5_web'
      if(sessionStorage.getItem('tokenChannel')){
        tempObj.tokenChannel = sessionStorage.getItem('tokenChannel') 
      }
    } else {
      config.headers['userToken'] = cookieStorage.getItem('user_token') || ''
      config.headers['customerID'] = cookieStorage.getItem('user_id') || ''
      tempObj.channel = cookieStorage.getItem('channel') || 'h5_web'
      if(cookieStorage.getItem('tokenChannel')){
        tempObj.tokenChannel = cookieStorage.getItem('tokenChannel') 
      }
    }

    config.headers['x-defined-appinfo'] = JSON.stringify(tempObj)
    // config.headers['channel'] = cookieStorage.getItem('channel') || '-1'
    
    //document.getElementById('loading-mask').className = 'visible'
    return config
  },
  function (error) {
  // Do something with request error
  return Promise.reject(error)
  }
)

// 添加一个响应拦截器
service.interceptors.response.use(function ( response ) {
  // Do something with response data
  // 此处可以根据返回的code来判断非系统异常问题
  //document.getElementById('loading-mask').className = ''
  // return response
  return response
}, function (error) {
  // Do something with response error
  return Promise.reject(error)
})

function request ( params ) {
  return new Promise((resolve, reject) => {
    service( params ).then( res => {
      resolve( res.data )
    }).catch( err => {
      reject( err )
    })
  })
}

export default {
  post (url, params,responseType) {
    return request({
      method: 'post',
      url: url,
      data: params,
      responseType:responseType
    })
  },
  get (url, params) {
    return request({
      method: 'get',
      url: helper.queryString(url, params)
    })
  },
  postCrypto(url, params){
    let str = ''
    for(let i =0;i<6;i++){
      str += String.fromCharCode(Math.floor(Math.random()*26+65))
    }
    let nowTime = Math.round(new Date().getTime()/1000.0)
    params.timestamp = nowTime
    let requestParams = str + Crypto.encryptData(params)
    return request({
        method: 'post',
        url: url,
        data: {
            'data':requestParams
        },
        headers :{
            'x-request-encryption':true
        }
    })
  }
}
