export default [
    {
        path: '/activityH5/fruitCoupon',
        meta:{
            title:'超甜水果券',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/fruitCoupon'], resolve)
    },
    {
        path: '/activityH5/download',
        meta:{
            title:'下载App',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/download'], resolve)
    },
    {
        path: '/activityH5/sfLetter',
        meta:{
            title:'给最爱的你',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/sfLetter'], resolve)
    },
    {
        path: '/activityH5/sfLetterExpired',
        meta:{
            title:'给最爱的你',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/sfLetterExpired'], resolve)
    },
    {
        path: '/activityH5/sfLetterT',
        meta:{
            title:'致用心生活的你',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/sfLetter_v2'], resolve)
    },
    {
        path: '/activityH5/sfLetterRenewT',
        meta:{
            title:'致用心生活的你',
            common: true
        },
        component: (resolve) => require(['@/pages/activityH5/sfLetterRenew'], resolve)
    },
  ]
  