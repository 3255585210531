import $axios from '@/utils/http'
import Base from './base'

export default class grabCoupon extends Base {
  // 获取页面初始信息 建立邀请关系
  getInit (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/init`, params)
  }
  // 领券
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/receive`, params)
  }
  // 获取一维码
  getCouponCode (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/getCouponCode`, params)
  }
  // 奖励金信息
  getBonusInfo (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/bonusInfo`, params)
  }
  // 邀请记录
  getInviteRecord (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/inviteRecord`, params)
  }
  // 提现记录
  getBonusRecord (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/bonusRecord`, params)
  }
  // 提现
  getExtract (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/extract`, params)
  }
  //分享
  setShare (params ) {
    return $axios.post(`${this.baseURL}/bonusMarch/share`, params)
  }
  // 获取秘钥
  getSign (params ) {
    return $axios.post(`${this.baseURL}/activitySign/getSign`, params)
  }
  // 获取定位信息
  getLocation ( params ) {
    return $axios.post(`${this.baseURL}/store/checkCity`, params)
  }
}
