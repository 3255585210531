import $axios from '@/utils/http'
import Base from './base'

export default class tryEat extends Base {
    // 获取试吃报告详情
    getReportDetails(params){
        return $axios.post(`${this.baseURL}/foretaste/report/detail`, params)
    }
    // 分页获取试吃报告评论
    getCommentList(params){
        return $axios.post(`${this.baseURL}/foretaste/comment/list`, params)
    }
    // 获取点赞状态
    getLikeStatus(params){
        return $axios.post(`${this.baseURL}/foretaste/report/isLike/report`, params)
    }
    // 评论点赞（取消点赞）
    setLaud(params){
        return $axios.post(`${this.baseURL}/foretaste/cancel/like`, params)
    }
    // 试吃报告发布评论
    releaseComment(params){
        return $axios.post(`${this.baseURL}/foretaste/release/comment`, params)
    }
    // 试吃报告删除评论
    deleteComment(params){
        return $axios.post(`${this.baseURL}/foretaste/comment/delete`, params)
    }
    // 获取举报选项
    getReportItem(params){
        return $axios.post(`${this.baseURL}/foretaste/report/itemQuery`, params)
    }
    // 举报报告
    accusationReport(params){
        return $axios.post(`${this.baseURL}/foretaste/report/accuse`, params)
    }
    // 举报评论
    // accusationComment(params){
    //     return $axios.post(`${this.baseURL}/foretaste/tipoff/comment`, params)
    // }
    // 确认试吃订单
    setOrderSumbit(params){
        return $axios.post(`${this.baseURL}/foretaste/activity/orderSubmit`, params)
    }
    // 获取订单详情
    getOrderDetail(params){
        return $axios.post(`${this.baseURL}/foretaste/order/detail`, params)
    }
    // 获取报告详情草稿
    getReportBatch(params){
        return $axios.post(`${this.baseURL}/foretaste/report/batchGet`, params)
    }
    // 新增试吃报告
    addReport(params){
        return $axios.post(`${this.baseURL}/foretaste/report/add`, params)
    }
    // 更新试吃报告
    updateReport(params){
        return $axios.post(`${this.baseURL}/foretaste/report/update`, params)
    }
    // 试吃报告完成分享
    setShareNotify(params){
        return $axios.post(`${this.baseURL}/foretaste/share`, params)
    }
    // 获取试吃报告二维码
    // getReportShareCode(params){
    //     return $axios.post(`${this.baseURL}/shareUrl/save`, params)
    // }
    //根据storeID获取门店信息（试吃）
    getActStoreInfo(params){
        return $axios.post(`${this.baseURL}/foretaste/store/detail`, params)
    }
    // 获取试吃中心数据
    getCenterList(params){
        return $axios.post(`${this.baseURL}/foretaste/center/list`, params)
    }
    // 获取用户参与的试吃活动 
    getCustomerJoinActivity (params) {
        return $axios.post(`${this.baseURL}/foretaste/customer/joinActivity`, params)
    }
    // 提醒 取消提醒
    subscribe (params) {
        return $axios.post(`${this.baseURL}/foretaste/activity/subscribe`, params)
    }
    // 获取试吃活动数据
    getActivityDetail (params) {
        return $axios.post(`${this.baseURL}/foretaste/activity/detail`, params)
    }
    // 获取试吃报告列表
    getReportList (params) {
        return $axios.post(`${this.baseURL}/foretaste/report/list`, params)
    }
    // 获取类目
    getCategorList (params) {
        return $axios.post(`${this.baseURL}/foretaste/category/list`, params)
    }
    // 获取申请记录
    getRecordList (params) {
        return $axios.post(`${this.baseURL}/foretaste/report/actRecordList`, params)
    }
    // 获取活动积分配置
    getIntegralConfig (params) {
        return $axios.post(`${this.baseURL}/foretaste/integral/config`, params)
    }
    //  转换生成小程序码
    setUrl( params ){
        return $axios.post(`${this.baseURL}/wx/setUrl`, params)
    }
    // 获取小程序码
    getActivityShareCode( params ){
        return $axios.post(`${this.baseURL}/wx/qrCodeCreate`, params,'blob')
    }
    // 小程序加入购物车 百果+ 使用
    wxMiniCardAdd( params ){
        return $axios.post(`${this.baseURL}/goodsCard/wxMiniCardAdd`, params)
    }
    // 获取报告用户举报状态
    getReportItemtHasAccusation (params) {
        return $axios.post(`${this.baseURL}/foretaste/reportItem/hasAccusation`, params)
    }
    
}