import cookieStorage from '@/utils/cookieStorage'
import helper from '@/utils/helper'

(function(){ 
  let url = window.location.href
  if( url.indexOf('openID') != -1 ) {
    let params = helper.getUrlParams()
    let s = 7*60*60*24
    // 存储微信参数
    cookieStorage.setItem('openId', params.openID, s)
    cookieStorage.setItem('nickname', params.nickName, s)
//    params.headimgurl = decodeURIComponent( params.headimgurl )
    cookieStorage.setItem('headimgurl', params.headimgurl, s)
    cookieStorage.setItem('version','2.7.2', s)
    // 删除微信参数
    delete params.headimgurl
    delete params.nickName
    delete params.openID
    // 判断 url 是否有参数
    let path = url.split("?")[0]
    if ( Object.keys( params ).length === 0 ) {
      window.location.href = path
    } else {
      window.location.href = helper.queryString(path, params)
    }  
  }
})()