import $axios from '@/utils/http'
import Base from './base'

export default class integral extends Base {
  // 获取积分记录
  getScoreList(params) {
    return $axios.post(`${this.baseURL}/integral/changeList`, params)
  }
  // 获取积分记录(不检验token)
  getScoreListV2(params) {
    return $axios.post(`${this.baseSlrURL}/fast/integral/changeList`, params)
  }
}
