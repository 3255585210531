import $axios from '@/utils/http'
import Base from './base'

export default class loveEatFestival extends Base {
    //导航及城市配置
    getConfig (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/config`, params)
    }
    // 单品信息配置
    getGoodsConf (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/goodsConf`, params)
    }
    // 基础信息配置  是否领过 礼包 有满52.7以上的订单 24号后 默认没有52.7 订单
    getBaseInfo (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/baseInfo`, params)
    }
    // 满52.7 以上可领 21-24
    orderPriceReceive (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/orderPriceReceive`, params)
    }
    // 邀请助力
    invitationHelp (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/invitationHelp`, params)
    }
    // 分享key值
    shareKey (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/shareKey`, params)
    }
    // 25-28基础券领取 9折
    receiveCoupon (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/receiveCoupon`, params)
    }
    // 分享升级
    upgradeCoupon (params ) {
        return $axios.post(`${this.baseURL}/loveEatFestival/upgradeCoupon`, params)
    }
    // 获取商品信息 批量
    getGoodsListInfo (params ) {
        return $axios.post(`${this.baseURL}/goods/detailBatch`, params)
    }
    // 获取附近服务门店
    getCheckCity (params ) {
        return $axios.post(`${this.baseURL}/store/checkCity`, params)
    }
} 