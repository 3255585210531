import $axios from '@/utils/http'
import Base from './base'

export default class Receive extends Base {
  // 获取奖励配件( 首页数据 ) 
  getUrl ( ) {
    return $axios.post(`${this.baseURL}/poster/info`)
  }
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/poster/receive`, params)
  }
}
