import $axios from '@/utils/http'
import Base from './base'

export default class weeklyLuckyDraw extends Base {
  // 抽奖
  luckyDraw ( params ) {
    return $axios.postCrypto(`${this.baseURL}/h5/plainFresh/luck`, params)
  }
//  获取轮播
  getBroadcast ( params ) {
    return $axios.post(`${this.baseURL}/h5/plainFresh/broadcast`, params)
  }
  // 填写地址
  addAddress ( params ) {
    return $axios.post(`${this.baseURL}/h5/plainFresh/addAddress`, params)
  }
   // 是否弹窗 及 参与过
   getInfo ( params ) {
    return $axios.post(`${this.baseURL}/h5/plainFresh/info`, params)
  }
} 