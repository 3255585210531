export default [
    {
        path: '/zfb/privacyInfo',
        meta:{
            title:'用户隐私政策',
            isWeb:true,
        },
        component: (resolve) => require(['@/pages/zfb/privacyInfoMenu'], resolve)
    },
    {
        //不在使用
        path: '/zfb/children/privacyInfo',
        meta:{
            title:'百果园+支付宝小程序儿童个人信息保护规则及监护人须知',
            isWeb:true,
        },
        component: (resolve) => require(['@/pages/zfb/childrenPrivacyInfo'], resolve)
    },
  ]
  