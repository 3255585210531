// 手机号脱敏
export function phoneEncryption( phone ) {
  if ( !phone ) return ''
  phone = phone.toString()
  return phone.substr(0,3) + '****' + phone.substr(-4)
}
// 昵称脱敏
/*
    用户昵称脱敏规则已补充：一个字的时候，显示全，两个字的时候，隐藏第一个（*华），两个字以上的时候，隐藏中间，只显示前后两个字（程*花）
*/
export function nickNameEncryption( name ) {
  if ( !name ) return ''
  if (name.length == 1) return name
  // if (name.length == 2) return '*' + name.substr(1,2)
  // if (name.length > 2) return name.substr(0,1) + '**' + name.substr(-1)
  if(name.length>1){
    return name.replace(/(^.{1})(.+)$/g, (...args) => {
      let tempStr = '';
      if (args[2] && args[2].length) {
          tempStr = Array.from({
              length: args[2].length + 1,
          }).join('*');
      }
      return args[1] + tempStr;
    })
  }
  // if(name.length > 2) return name.substr(0,1) + name.replace(/.(?=.)/g, '*').slice(1)
}

 // 天处理
 export function dealDate (time) {
  let monthNum = ''
  let dateNum = time.slice(5,6)
  let oneDataNum = time.slice(6,7)
  let secDateNum = time.slice(5,7)
  return monthNum = dateNum > 0 ? secDateNum : oneDataNum
}
// 日处理
export function dealDay (time) {
  let dayNum = ''
  let dateNum = time.slice(8,9)
  let oneDataNum = time.slice(9,10)
  let secDateNum = time.slice(8,10)
  return dayNum = dateNum > 0 ? secDateNum : oneDataNum
}