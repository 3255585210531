export default [
  {
    path: '/pullNew/information',
    meta:{
      title: '员工信息'
    },
    component: (resolve) => require(['@/pages/pullNew/information'], resolve)
  },
  {
    path: '/pullNew/invite',
    meta:{
      title: '百果园'
    },
    component: (resolve) => require(['@/pages/pullNew/redPacket'], resolve)
  },
]