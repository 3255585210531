export default [
    {
        path: '/app/returnGoods',
        meta:{
            title:'“不好吃三无退货”规则',
            // common: true,
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/returnGoodsMenu'], resolve)
    },
    {
        path: '/app/terms',
        meta:{
            title:'百果园用户服务协议',
            // common: true,
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/termsMenu'], resolve)
        // component: (resolve) => require(['@/pages/app/terms0619'], resolve)
        // component: (resolve) => require(['@/pages/app/terms'], resolve)
    },
    {
        path: '/app/test',
        meta:{
            title:'测试页面',
            common: true
        },
        component: (resolve) => require(['@/pages/app/test'], resolve)
    },
    {
        path: '/app/test_v2',
        meta:{
            title:'测试页面',
            //common: true
        },
        component: (resolve) => require(['@/pages/app/test'], resolve)
    },
    {
        path: '/app/marginAgreement',
        meta:{
            title:'保证金协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/marginAgreement'], resolve)
    },
    {
        path: '/app/privacyInfo',
        meta:{
            title:'用户隐私政策',
            // common: true,
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/privacyInfoMenu'], resolve)
    },
    {
        path: '/app/memberService',
        meta:{
            title:'百果心享会员服务协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/memberService'], resolve)
    },
    {
        path: '/app/openAccount',
        meta:{
            title:'电子账户协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/openAccount'], resolve)
    },
    {
        path: '/app/productService',
        meta:{
            title:'电子账户产品服务协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/productService'], resolve)
    },
    {
        path: '/app/customerService',
        meta:{
            title:'电子账户客户服务协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/customerService'], resolve)
    },
    {
        path: '/app/payment',
        meta:{
            title:'快捷支付协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/payment'], resolve)
    },
    {
        path: '/app/refundInstruction',
        meta:{
            title:'一年省不足实付会费，差多少退多少',
            common: true
        },
        component: (resolve) => require(['@/pages/app/refundInstruction'], resolve)
    },
    {
        path: '/app/taxRevenue',
        meta:{
            title:'“中国税收居民”说明',
            common: true
        },
        component: (resolve) => require(['@/pages/app/taxRevenue'], resolve)
    },
    {
        path: '/app/userPrivacy',
        meta:{
            title:'用户信息隐私协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/userPrivacy'], resolve)
    },
    {
        path: '/app/walletPromise',
        meta:{
            title:'百果园会员钱包支付承诺协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/walletPromise'], resolve)
    },
    {
        path: '/download',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/app/download'], resolve)
    },
    {
        path: '/app/download',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/app/download'], resolve)
    },
    {
        path: '/downloadDE',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/app/downloadDE'], resolve)
    },
    {
        path: '/indemnityInfo',
        meta:{
            title:'超时赔券服务协议',
            common: true
        },
        component: (resolve) => require(['@/pages/app/indemnityInfo'], resolve)
    },
    {
        path: '/indemnity',
        meta:{
            title:'“超时赔券”服务政策',
            common: true
        },
        component: (resolve) => require(['@/pages/app/indemnity'], resolve)
    },
    {
        path: '/app/memberInterduce',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/app/memberInterduce'], resolve)
    },
    {
        path: '/app/privilege',
        meta:{
            title:'权益说明',
        },
        component: (resolve) => require(['@/pages/app/privilege'], resolve)
    },
    {
        path: '/app/aboutJoining',
        meta:{
            title:'关于加盟',
            common: true
        },
        component: (resolve) => require(['@/pages/app/aboutJoining'], resolve)
    },
    {
        path: '/app/aboutUs',
        meta:{
            title:'关于我们',
            common: true
        },
        component: (resolve) => require(['@/pages/app/aboutUs'], resolve)
    },
    {
        path: '/app/berryRoleInfo',
        meta:{
            title:'果粒值规则说明',
            common: true
        },
        component: (resolve) => require(['@/pages/app/berryRoleInfo'], resolve)
    },
    {
        path: '/app/intergraRoleInfo',
        meta:{
            title:'积分规则说明',
            common: true
        },
        component: (resolve) => require(['@/pages/app/intergraRoleInfo'], resolve)
    },
    {
        path: '/app/invoice',
        meta:{
            title:'发票须知',
            isWeb: true
        },
        component: (resolve) => require(['@/pages/app/invoice'], resolve)
    },
    {
        path: '/app/memberRoleInfo',
        meta:{
            title:'会员等级积分说明',
            common: true
        },
        component: (resolve) => require(['@/pages/app/memberRoleInfo'], resolve)
    },
    {
        path: '/jump/wxMini/:code',
        meta:{
            title:'百果园',
            common: true
        },
        component: (resolve) => require(['@/pages/app/jumpWxMini'], resolve)
    },
    // pc端
    {
        path: '/topUp',
        meta:{
            title:'充值码页面',
            common: true
        },
        component: (resolve) => require([(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? '@/pages/h5/topUp':'@/pages/app/topUp'], resolve)
    },
    {
        path: '/indemnityPolicy',
        meta:{
            title:'“超时赔券”服务政策',
            common: true
        },
        component: (resolve) => require(['@/pages/app/indemnityPolicy'], resolve)
    },
    {
        path: '/app/prepaidCard',
        meta:{
            title:'百果园预付卡管理章程',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/prepaidCardMenu'], resolve)
    },
    {
        path: '/cancelRenew',
        meta:{
            title:'取消“自动续费”操作指南',
            common: true
        },
        component: (resolve) => require(['@/pages/app/cancelRenew'], resolve)
    },
    {
        path: '/app/personalInfoList',
        meta:{
            title:'个人信息清单',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/personalInfoList'], resolve)
    },
    {
        path: '/app/tripartiteInfoList',
        meta:{
            title:'第三方信息共享清单',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/tripartiteInfoList'], resolve)
    },
    {
        path: '/app/tripartiteInfoList/info',
        meta:{
            title:'百果园第三方SDK说明',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/tripartiteInfoListInfo'], resolve)
    },
    //百果园app和小程序的
    {
        path: '/app/childInfoProtection',
        meta:{
            title:'百果园儿童个人信息保护规则及监护人须知',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/childInfoProtection'], resolve)
    },
    {
        path: '/app/userLogout',
        meta:{
            title:'用户注销协议',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/userLogout'], resolve)
    },
    {
        path: '/app/convention',
        meta:{
            title:'百果园用户行为公约',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/convention'], resolve)
    },
    {
        //不在使用 百果园团购公众号的
        path: '/app/childrenInfoProtection',
        meta:{
            title:'百果园儿童个人信息保护规则及监护人须知',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/childrenInfoProtection'], resolve)
    },
    {
        //不在使用
        path: '/app/giftCardChildInfoProtection',
        meta:{
            title:'百果园礼品卡儿童个人信息保护规则及监护人须知',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/giftCardChildInfoProtection'], resolve)
    },
    {
        path: '/app/recommendedAlgorithmRegulation',
        meta:{
            title:'百果园算法推荐服务说明',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/recommendedAlgorithmRegulation'], resolve)
    },
    {
        //不在使用
        path: '/app/enterpriseChildPrivacy',
        meta:{
            title:'百果园企业购商城儿童个人信息保护规则及监护人须知',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/enterpriseChildPrivacy'], resolve)
    },
    {
        path: '/app/userRechargeRegulation',
        meta:{
            title:'充值服务个人信息授权声明',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/topUpPersonalInfoStatement'], resolve)
    },
    {
        path: '/app/invoiceRegulation',
        meta:{
            title:'发票服务个人信息授权声明',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/invoiceRegulation'], resolve)
    },
    {
        path: '/app/giftCardPrivacyPolicy',
        meta:{
            title:'百果园礼品卡隐私政策',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/giftCardPrivacyMenu'], resolve)
    },
    {
        path: '/app/groupBuyPrivacyInfo',
        meta:{
            title:'百果园团购隐私政策',
            common: true,
        },
        component: (resolve) => require(['@/pages/app/groupBuyPrivacyMenu'], resolve)
    },
    {
        path: '/previewPDF/:arrOrgCode/:goodsCode',
        meta:{
            title:'检测报告',
            common: true
        },
        component: (resolve) => require(['@/pages/app/previewPDF'], resolve)
    },
    {
        path: '/app/privacyAbstract',
        meta:{
            title:'百果园隐私政策摘要',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/privacyAbstract'], resolve)
    },
    {
        path: '/app/userInfoBaseMode',
        meta:{
            title:'百果园个人信息处理规则（基本功能模式）',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/userInfoBaseMode'], resolve)
    },
    {
        path: '/app/personnelManagementSystem',
        meta:{
            title:'百果园人事管理系统隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/personnelManagementSystem'], resolve)
    },
    {
        path: '/app/privacyLogin',
        meta:{
            title:'百果园-登录隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/privacyLogin'], resolve)
    },
    {
        path: '/app/privacyShop',
        meta:{
            title:'百果园-网上购物功能隐私政策',
            // common: true,
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/privacyShop'], resolve)
    },
    {
        path: '/app/privacyExtend',
        meta:{
            title:'百果园-扩展功能隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/privacyExtend'], resolve)
    },
    {
        path: '/app/memberAddService',
        meta:{
            title:'百果园-会员增值服务隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/membersValueAdded'], resolve)
    },
    {
        path: '/app/infoService',
        meta:{
            title:'百果园-信息交互服务隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/informationInteraction'], resolve)
    },
    {
        path: '/app/customerServiceGuarantee',
        meta:{
            title:'百果园-客服保障服务隐私政策',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/customerServiceGuarantee'], resolve)
    },
    {
        path: '/app/storeQualification',
        meta:{
            title:'门店资质',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/storeQualification'], resolve)
    },
    {
        path: '/app/vegReturnGoods',
        meta:{
            title:'“次日达缺货双倍退”规则',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/vegReturnGoods'], resolve)
    },
    {
        path: '/app/compensation',
        meta:{
            title:'“错一赔十”规则',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/compensationMenu'], resolve)
    },
    {
        path: '/app/protocolPage',
        meta:{
            title:'协议页面',
            common: true,
            // isWeb: true,
        },
        component: (resolve) => require(['@/pages/app/protocolPage'], resolve)
    },
    {
        path: '/wxpay',
        meta:{
            title:'百果园',
        },
        component: (resolve) => require(['@/pages/app/wxpay'], resolve)
    },
    {
        path: '/cs/upload/:sceneID/:taskID',
        meta:{
            title:'百果园',
        },
        component: (resolve) => require(['@/pages/app/csUpload'], resolve)
    },
    
    
  ]
  