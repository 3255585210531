import { omit } from "lodash"

const helper = {
  // 拼接参数至url
  queryString (url, query) {
    let str = []
    for (let key in query) {
      query[key] && str.push(key + '=' + query[key])
    }
    let paramStr = str.join('&')
    return paramStr ? `${url}?${paramStr}` : url
  },
  // 获取url参数， 返回对象 ex: { inviteID: 24 }
  getUrlParams () {
    let params = {}
    let url = decodeURIComponent(decodeURIComponent(window.location.href))
    let arr = url.split("?")
    if( !arr[1] ) return params
    let paramsItems = arr[1].split("&")
    for(let paramsItem of paramsItems) {
      let paramsKey = paramsItem.split("=")[0]
      let paramsValue = paramsItem.split("=")[1]
      params[paramsKey] = paramsValue
    }
    return params
  },
  // 获取微信分享的链接参数
  getShareUrlParams() {
    let params = {}
    let url = window.location.href 
    let i = url.lastIndexOf('?')
    i++
    let paramsStr = url.slice(i)
    let paramsItems = paramsStr.split("&")
    for(let paramsItem of paramsItems) {
      let paramsKey = paramsItem.split("=")[0]
      let paramsValue = paramsItem.split("=")[1]
      params[paramsKey] = paramsValue
    }
    return params
  },
  // 会员俱乐部权益页删除privilegeID参数
  removePrivilegeParams(path, params) {
    if (path === '/memberClub/privilege') return omit(params, ['privilegeID'])
    return params
  }
}
export default helper
