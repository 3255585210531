export default [
  {
      path: '/test',
      meta:{
          title:'测试',
          common: true
      },
      component: (resolve) => require(['@/pages/test/index'], resolve)
  },
  {
      path: '/surpriseeveryday/config',
      meta:{
          title:'小程序原生分享',
          //common: true

      },
      component: (resolve) => require(['@/pages/surpriseEveryDay/Config'], resolve)
  },
  {
    path: '/shareMini/test',
    meta:{
        title:'测试',
        common: true
    },
    component: (resolve) => require(['@/pages/test/shareMiniTest'], resolve)
  },
  {
    path: '/panda/test',
    meta:{
        title:'生鲜测试页',
    },
    component: (resolve) => require(['@/pages/test/pandaTest'], resolve)
},
]
