export default [
    {
        path: '/memberClub/fruitList',
        meta:{
            title: '我的果粒值',
            screenName:'我的果粒值',
            screenCode:'3022'
        },
        component: (resolve) => require(['@/pages/memberClub/fruitList'], resolve)
    },
    {
        path: '/memberClub/rule',
        meta:{
            title: '等级规则',
            screenName:'等级规则说明',
            screenCode:'3023'
        },
        component: (resolve) => require(['@/pages/memberClub/rule'], resolve)
      },
      {
        path: '/memberClub/privilege',
        meta:{
            title: '权益详情',
            screenName:'权益详情页',
            screenCode:'3021'
        },
        component: (resolve) => require(['@/pages/memberClub/privilege'], resolve)
      },
      {
        path: '/memberClub/index',
        meta:{
            title: '会员俱乐部',
            screenName:'会员俱乐部页',
            screenCode:'3020'
        },
        component: (resolve) => require(['@/pages/memberClub/index'], resolve)
      },
  ]
  