export default [
    {
      path: '/memberHoliday/index',
      meta:{
        title: '心享会员节'
      },
      component: (resolve) => require(['@/pages/memberHoliday/index'], resolve)
    },
    {
        path: '/memberHoliday/openTransitionPage',
        meta:{
          title: '百果园'
        },
        component: (resolve) => require(['@/pages/memberHoliday/openTransitionPage'], resolve)
    },
    {
      path: '/memberHoliday/wxTransitionPage/:code',
      meta:{
        title: '百果园'
      },
        component: (resolve) => require(['@/pages/memberHoliday/wxTransitionPage'], resolve)
    },
    {
      path: '/memberHoliday/h5TransitePage/:code',
      meta:{
        title: '百果园'
      },
        component: (resolve) => require(['@/pages/memberHoliday/h5TransitePage'], resolve)
    },
    {
        path: '/memberHoliday/drawSharePage',
        meta:{
          title: '百果园'
        },
        component: (resolve) => require(['@/pages/memberHoliday/drawSharePage'], resolve)
    },
    {
        path: '/memberHoliday/smileStar',
        meta:{
          title: '心享微笑之星'
        },
        component: (resolve) => require(['@/pages/memberHoliday/smileStar'], resolve)
    },
    {
      path: '/memberHoliday/voteSuccess/:code',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/memberHoliday/voteSuccess'], resolve)
    },
    {
      path: '/memberHoliday/signUp',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/memberHoliday/signUp'], resolve)
    },
    {
      path: '/memberHoliday/uploadImgTransite',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/memberHoliday/uploadImgTransite'], resolve)
    },
  ]