import $axios from '@/utils/http'
import Base from './base'

export default class loveEat extends Base {
  // 接口 初始化
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/eatFruit/init`, params)
  }
  //领券
  getCoupon ( params ) {
    return $axios.post(`${this.baseURL}/eatFruit/receive`, params)
  }
} 