export default [
    {
      path: '/h5/dragonBoat',
      meta:{
        title: '快看看你最粽意谁'
      },
      component: (resolve) => require(['@/pages/h5/dragonBoat'], resolve)
    },
    {
      path: '/h5/welfare',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/welfare79'], resolve)
    },
    {
      path: '/h5/storeWelfare',
      meta:{
        title: '百果园',
      },
      component: (resolve) => require(['@/pages/h5/storeWelfare'], resolve)
    },
    {
      path: '/h5/naFruitFes',
      meta:{
        title: '国民水果节'
      },
      component: (resolve) => require(['@/pages/h5/naFruitFes'], resolve)
    },
    {
      path: '/h5/naFruitFesWeb',
      meta:{
        title: '国民水果节',
        common:true,
      },
      component: (resolve) => require(['@/pages/h5/naFruitFesWeb'], resolve)
    },
    {
      path: '/h5/redPacketRain',
      meta:{
        title: '百果心享会员2周年-抢千万红包雨'
      },
      component: (resolve) => require(['@/pages/h5/redPacketRain'], resolve)
    },
    {
      path: '/h5/sfStrategy',
      meta:{
        title: '百果心享会员2周年-玩转攻略'
      },
      component: (resolve) => require(['@/pages/h5/sfStrategy'], resolve)
    },
    {
      path: '/h5/thinksLetter',
      meta:{
        title: '百果心享会员2周年-感恩有您',
        common:true,
      },
      component: (resolve) => require(['@/pages/h5/thinksLetter'], resolve)
    },
    {
      path: '/h5/dwBanana',
      meta:{
        title: '一分钱吃香蕉-东莞市',
        common:true,
      },
      component: (resolve) => require(['@/pages/h5/dwBanana'], resolve)
    },
    {
      path: '/h5/freshDay',
      meta:{
        title: '国民生鲜节'
      },
      component: (resolve) => require(['@/pages/h5/freshDay'], resolve)
    },
    {
      path: '/h5/invite/:actId',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/invite'], resolve)
    },
    {
        path: '/h5/fillAddress/:id',
        meta: {
            title: '百果园'
        },
        component: (resolve) => require(['@/pages/h5/fillAddress'], resolve)
    },
    {
        path: '/h5/de',
        meta:{
            title:'11.11百果园店狂欢'
        },
        component: (resolve) => require(['@/pages/h5/doubleEleven'], resolve)
    },
    {
      path: '/h5/cashDraw',
      meta:{
        title: '百元现金天天抽',
      },
      component: (resolve) => require(['@/pages/h5/cashDraw'], resolve)
    },
    {
      path: '/h5/search',
      meta:{
          title:'百果园',
          common:true,
      },
      component: (resolve) => require(['@/pages/h5/search'], resolve)
    },
    {
      path: '/h5/DTFreshDay',
      meta:{
        title: '双十二国民生鲜节'
      },
      component: (resolve) => require(['@/pages/h5/DTFreshDay/index'], resolve)
    },
    {
      path: '/h5/DTInvite/:actId',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/DTFreshDay/DTInvite'], resolve)
    },
    {
        path: '/h5/DTFillAddress/:id',
        meta: {
            title: '百果园'
        },
        component: (resolve) => require(['@/pages/h5/DTFreshDay/DTFillAddress'], resolve)
    },
    {
      path: '/h5/dt',
      meta:{
          title:'百果园店双十二狂欢'
      },
      component: (resolve) => require(['@/pages/h5/doubleTwelve'], resolve)
  },
    {
      path: '/h5/christmas',
      meta:{
          title:'百果园'
      },
      component: (resolve) => require(['@/pages/h5/christmas'], resolve)
    },
    {
      path: '/h5/christmasShareImg',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/christmasShareImg'], resolve)
    },
    {
      path: '/h5/collectCard',
      meta:{
          title: '集福卡 赢免费草莓'
      },
      component: (resolve) => require(['@/pages/h5/collectCard'], resolve)
    },
    {
      path: '/h5/collectCardShareImg',
      meta:{
          title: '集福卡 赢免费草莓'
      },
      component: (resolve) => require(['@/pages/h5/collectCardShareImg'], resolve)
    },
    {
        path: '/h5/lanternFes',
        meta: {
            title: '好果子闹元宵'
        },
        component: (resolve) => require(['@/pages/h5/lanternFes'], resolve)
    },
    {
      path: '/h5/vegLottery',
      meta:{
          title: '001生态蔬菜福利抢鲜抽'
      },
      component: (resolve) => require(['@/pages/h5/vegLottery'], resolve)
    },
    {
      path: '/h5/vegInfo',
      meta:{
          title: '001生态蔬菜福利抢鲜抽'
      },
      component: (resolve) => require(['@/pages/h5/vegInfo'], resolve)
    },
    {
      path: '/h5/vegShare',
      meta:{
          title: '001生态蔬菜福利抢鲜抽'
      },
      component: (resolve) => require(['@/pages/h5/vegShare'], resolve)
    },
    {
      path: '/h5/orderInvite',
      meta:{
          title: '邀好友赚“现金”'
      },
      component: (resolve) => require(['@/pages/h5/orderInvite'], resolve)
    },
    {
      path: '/h5/orderInviteReward',
      meta:{
          title: '邀好友赚“现金”'
      },
      component: (resolve) => require(['@/pages/h5/orderInviteReward'], resolve)
    },
    {
      path: '/h5/adoptCow',
      meta:{
          title: '认养一头牛'
      },
      component: (resolve) => require(['@/pages/h5/adoptCow/index'], resolve)
    },
    {
      path: '/h5/loveEatDay',
      meta:{
          title: '熊猫大鲜爱吃节'
      },
      component: (resolve) => require(['@/pages/h5/loveEatDay/index'], resolve)
    },
    {
      path: '/h5/loveEat/invite/:actId',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/loveEatDay/invite'], resolve)
    },
    {
      path: '/h5/loveEat',
      meta:{
          title: '327爱吃节'
      },
      component: (resolve) => require(['@/pages/h5/loveEat'], resolve)
    },
    {
      path: '/visitors/:key',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/visitors'], resolve)
    },
    {
      path: '/h5/takeOut',
      meta:{
          title: '赢免费果蔬'
      },
      component: (resolve) => require(['@/pages/h5/takeOut'], resolve)
    },
    {
      path: '/h5/takeOutImg/:deliveryNum/:averageDeliveryTime/:key',
      meta:{
          title: '赢免费果蔬'
      },
      component: (resolve) => require(['@/pages/h5/takeOutImg'], resolve)
    },
    {
      path: '/h5/exhibition',
      meta:{
          title: '熊猫大仙'
      },
      component: (resolve) => require(['@/pages/h5/exhibition'], resolve)
    },
    {path: '/h5/fresh618',
      meta:{
          title: '熊猫大鲜618'
      },
      component: (resolve) => require(['@/pages/h5/fresh618/index'], resolve)
    },
    {
      path: '/h5/icedFruit',
      meta:{
          title: '冰镇鲜果料理展'
      },
      component: (resolve) => require(['@/pages/h5/icedFruit/index'], resolve)
    },
    {
      path: '/h5/upload',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/icedFruit/upload'], resolve)
    },
    {
      path: '/h5/JBYQ',
      meta:{
          title: '集百益纤'
      },
      component: (resolve) => require(['@/pages/h5/JBYQ'], resolve)
    },
    {
      path: '/h5/JBYQImg/:key/:posterIndex',
      meta:{
          title: '集百益纤+',
          isWeb:true

      },
      component: (resolve) => require(['@/pages/h5/JBYQImg'], resolve)
    },
    {
      path: '/h5/summerFun',
      meta:{
          title: '夏日狂欢节'
      },
      component: (resolve) => require(['@/pages/h5/summerFun'], resolve)
    },
    {
      path: '/h5/anniversary/coupons',
      meta:{
          title: '百果园App5周年庆'
      },
      component: (resolve) => require(['@/pages/h5/anniversary/coupon'], resolve)
    },
    {
      path: '/h5/anniversary/collecting',
      meta:{
          title: '百果园App5周年庆'
      },
      component: (resolve) => require(['@/pages/h5/anniversary/collecting'], resolve)
    },
    {
      path: '/h5/anniversary/message',
      meta:{
          title: '百果园App5周年庆'
      },
      component: (resolve) => require(['@/pages/h5/anniversary/message'], resolve)
    },
    {
      path: '/h5/anniversary/letter',
      meta:{
          title: '百果园App5周年庆',
          common: true
      },
      component: (resolve) => require(['@/pages/h5/anniversary/letter'], resolve)
    },
    {
      path: '/h5/nationalDay',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/nationalDay/index'], resolve)
    },
    {
      path: '/h5/activityStore',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/activityStore'], resolve)
    },
    {
      path: '/h5/greetingCard',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/greetingCard'], resolve)
      // component: (resolve) => require([Date.parse(new Date()) < Date.parse(new Date('2021/12/24 21:00:00')) && Date.parse(new Date()) > Date.parse(new Date('2021/12/20 00:00:00'))?'@/pages/h5/christmas2021/index':'@/pages/h5/greetingCard'], resolve)
    
    },
    {
      path: '/h5/christmas2021',
      meta:{
          title: '集卡片 领免费平安果'
      },
      component: (resolve) => require(['@/pages/h5/christmas2021/index'], resolve)
    },
    {
      path: '/h5/christmas2112',
      meta:{
          title: '集卡片 领免费平安果'
      },
      component: (resolve) => require(['@/pages/h5/christmas2021/index'], resolve)
    },
    {
      path: '/h5/springFestival',
      meta:{
          title: '年货节'
      },
      component: (resolve) => require(['@/pages/h5/springFestival/index'], resolve)
    },
    {
      path: '/h5/loveEatMay',
      meta:{
          title: '527爱吃节'
      },
      component: (resolve) => require(['@/pages/h5/loveEatMay/index'], resolve)
    },
    {
      path: '/h5/bgxxFes',
      meta:{
          title: '7月心享会员节'
      },
      component: (resolve) => require(['@/pages/h5/bgxxFes/index'], resolve)
    },
    {
      path: '/h5/sixAnniversary/bgy818',
      meta:{
          title: '百果园App6周年庆'
      },
      component: (resolve) => require(['@/pages/h5/sixAnniversary/bgy818'], resolve)
    },
    {
      path: '/h5/sixAnniversary/freeOrder',
      meta:{
          title: '百果园App6周年庆'
      },
      component: (resolve) => require(['@/pages/h5/sixAnniversary/freeOrder'], resolve)
    },
    {
      path: '/h5/midAutumn2022/index',
      meta:{
          title: '中秋节•悦好吃月团圆'
      },
      component: (resolve) => require(['@/pages/h5/midAutumn2022/index'], resolve)
    },
    {
      path: '/h5/freshFes',
      meta:{
          title: '次日达品质生鲜节'
      },
      component: (resolve) => require(['@/pages/h5/freshFes922/index'], resolve)
    },
    {
      path: '/anniversary/sfvip',
      meta:{
        title: '百果心享会员4周年',
      },
      component: (resolve) => require(['@/pages/h5/redPacketRain2022/index'], resolve)
    },
    {
      path: '/h5/christmas2022',
      meta:{
          title: 'pagoda圣诞季'
      },
      component: (resolve) => require(['@/pages/h5/christmas2022/index'], resolve)
    },
    {
      path: '/h5/lanternFestival2023',
      meta:{
          title: '闹元宵,庆团圆'
      },
      component: (resolve) => require(['@/pages/h5/lanternFestival2023/index'], resolve)
    },
    {
      path: '/h5/memberFestival',
      meta:{
          title: '心享暖春节'
      },
      component: (resolve) => require(['@/pages/h5/memberFestival/index'], resolve)
    },
    {
      path: '/h5/memberEatFestival',
      meta:{
          title: '7月心享好吃节'
      },
      component: (resolve) => require(['@/pages/h5/memberEatFestival/index'], resolve)
    },
    {
      path: '/h5/fruitFans',
      meta:{
          title: '果粉福利',
          screenName:'果粉福利',
          screenCode:'3013'
      },
      component: (resolve) => require(['@/pages/h5/fruitFans'], resolve)
    },
    {
      path: '/h5/sevenAnniversary/index',
      meta:{
          title: '百果园App7周年庆'
      },
      component: (resolve) => require(['@/pages/h5/sevenAnniversary/index'], resolve)
    },
    {
      path: '/h5/scratchCard',
      meta:{
          title: '百果园'
      },
      component: (resolve) => require(['@/pages/h5/scratchCard/index'], resolve)
    },
    {
      path: '/anniversary/sfvip23',
      meta:{
        title: '百果心享会员5周年',
      },
      component: (resolve) => require(['@/pages/h5/sfvip2023/index'], resolve)
    },
    {
      path: '/anniversary/playGame',
      meta:{
        title: '百果心享会员5周年',
      },
      component: (resolve) => require(['@/pages/h5/sfvip2023/playGame'], resolve)
    },
    {
      path: '/h5/christmas2023',
      meta:{
          title: '果真有好运'
      },
      // component: (resolve) => require(['@/pages/h5/notStarted'], resolve)
      component: (resolve) => require(['@/pages/h5/christmas2023/index'], resolve)
    },
    {
      path: '/h5/newyear2024',
      meta:{
        title: '果真有好运',
      },
      component: (resolve) => require(['@/pages/h5/newyear2024/index'], resolve)
    },
    {
      path: '/h5/springFestival2024',
      meta:{
        title: '抽红包 最高赢888元果币',
      },
      component: (resolve) => require(['@/pages/h5/springFestival2024/index'], resolve)
    },
    {
      path: '/h5/draw',
      meta:{
        title: '果真有好运',
      },
      component: (resolve) => require(['@/pages/h5/draw2024/index'], resolve)
    },
    {
      path: '/h5/memberFestivalApr',
      meta:{
        title: '心享会员节',
        screenName:'4月心享会员节',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberFestivalApr/index'], resolve)
    },
    {
      path: '/h5/memberLottery',
      meta:{
        title: '心享独宠礼遇',
        screenName:'心享独宠礼遇',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberLottery/index'], resolve)
    },
    {
      path: '/h5/DBFes',
      meta:{
        title: '全球好果贺端午',
      },
      component: (resolve) => require(['@/pages/h5/DBFes/index'], resolve)
    },
    {
      path: '/h5/memberFestivalJuly',
      meta:{
        title: '心享会员节',
        screenName:'7月心享会员节',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberFestivalJuly/index'], resolve)
    },
    {
      path: '/h5/memberFestivalJuly/game',
      meta:{
        title: '心享会员节',
        screenName:'7月心享会员节',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberFestivalJuly/game'], resolve)
    },
    {
      path: '/h5/anniversary2024',
      meta:{
        title: '818百果园线上八周年庆',
        screenName:'818周年庆',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/anniversary2024/index'], resolve)
    },
    {
      path: '/h5/midFes',
      meta:{
        title: '中秋好运福签',
      },
      component: (resolve) => require(['@/pages/h5/midFes/index'], resolve)
    },
    {
      path: '/h5/member2024/draw',
      meta:{
        title: '心享周年庆',
        screenName:'心享周年庆刮刮卡',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberAnniversary2024/draw'], resolve)
    },
    {
      path: '/h5/member2024/index',
      meta:{
        title: '心享周年庆',
        screenName:'心享周年庆膨胀券',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/memberAnniversary2024/index'], resolve)
    },
    {
      path: '/h5/thanksgiving2024',
      meta:{
        title: '感恩有你好果相伴',
        screenName:'感恩节活动2024',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/thanksgiving2024/index'], resolve)
    },
    {
      path: '/h5/newYear2025',
      meta:{
        title: '抽新年签 果真有好运',
        screenName:'元旦活动2025',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/h5/newYear2025/index'], resolve)
    },
  ]
