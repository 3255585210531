export default [
    {
        path: '/xmdx/communityGroup',
        meta:{
            title:'百果园推荐官推广服务协议',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/xmdx/communityGroupMenu'], resolve)
    },
    {
        path: '/xmdx/index',
        meta:{
            title:'百果园推荐官隐私政策',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/xmdx/indexMenu'], resolve)
    },
    {
        path: '/xmdx/service',
        meta:{
            title:'百果园推荐官用户服务协议',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/xmdx/serviceMenu'], resolve)
    },
    {
        path: '/xmdx/childInfoProtection',
        meta:{
            title:'百果园推荐官儿童个人信息保护规则及监护人须知',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/xmdx/childInfoProtection20220428'], resolve)
    },
    {
        path: '/xmdx/userLogoutProtocol',
        meta:{
            title:'用户注销协议',
            isWeb: true,
        },
        component: (resolve) => require(['@/pages/xmdx/userLogoutProtocol'], resolve)
    },
  ]
  