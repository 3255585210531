export default [
  {
    path: '/loveEat/index',
    meta:{
      title: '427爱吃节'
    },
    component: (resolve) => require(['@/pages/loveEat/index'], resolve)
  },
  {
    path: '/loveEat/goBuyC',
    meta:{
      title: '买好菜',
      common: true
    },
    component: (resolve) => require(['@/pages/loveEat/goBuyC'], resolve)
  }
]