import CryptoJS from 'crypto-js'
const keyStr = 'h5Pagoda'

function padKey(keyStr){
    let key = ''
    if(keyStr){
        let len = String(keyStr).length
        if(len<16){
            key = String(keyStr);
            for (let i = len; i < 16; i++) {
                key += "0";
            }
        }else{
            key = String(keyStr);
        }
    }
    return key
}

export default {//加密
    encryptData(data){
        let key  = CryptoJS.enc.Utf8.parse(padKey(keyStr));
        let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
            format: CryptoJS.format.Hex
        });
        return encrypted.toString().toUpperCase();
    },
    DecryptData(data){//解密
        let key = CryptoJS.enc.Utf8.parse(padKey(keyStr));
        let decrypted = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(data),key,{
            mode:CryptoJS.mode.ECB,
            padding:CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decrypted.toString())
        // return decrypted.toString()
    }
}