export default [
    {
        path: '/wxpay',
        meta:{
            title:'百果园',
        },
        component: (resolve) => require(['@/pages/pay/wxpay'], resolve)
    },
    {
        path: '/payUnifiedOrder',
        meta:{
            title:'百果园',
            common: true,
        },
        component: (resolve) => require(['@/pages/pay/unifiedOrder'], resolve)
    },
]