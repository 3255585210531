export default [
    {
        path: '/sfvip/index',
        meta:{
            title: '开心享，领好礼'
        },
        component: (resolve) => require(['@/pages/sfvip/index'], resolve)
    },
    {
        path: '/sfvip/buy/:superVipStatus/:couponStatus',
        meta:{
            title: '百果心享购买'
        },
        component: (resolve) => require(['@/pages/sfvip/buy'], resolve)
    },
    {
        path: '/sfvip/loading/:payID',
        meta:{
          title: '开通心享会员'
        },
        component: (resolve) => require(['@/pages/sfvip/loading'], resolve)
    },
    {
        path: '/sfvip/package',
        meta:{
            title: '开心享，领好礼'
        },
        component: (resolve) => require(['@/pages/sfvip/index_v2'], resolve)
    },
    {
        path: '/sfvip/package/buy/:superVipStatus/:couponStatus',
        meta:{
            title: '百果心享购买'
        },
        component: (resolve) => require(['@/pages/sfvip/buy'], resolve)
    },
    {
        path: '/sfvip/package/loading/:payID',
        meta:{
          title: '开通心享会员'
        },
        component: (resolve) => require(['@/pages/sfvip/loading_v2'], resolve)
    },
    {
        path: '/sfvip/package/exchange/:privilegeID/:code',
        meta:{
          title: '兑换权益'
        },
        component: (resolve) => require(['@/pages/sfvip/exchangePrivilege'], resolve)
    },
]