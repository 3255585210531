export default [
    {
      path: '/anniversary/index',
      meta:{
        title: '周年庆',
      },
      component: (resolve) => require(['@/pages/anniversary/NJ'], resolve)
    },
    {
      path: '/anniversary/GZ',
      meta:{
        title: '周年庆',
        common:true,
      },
      component: (resolve) => require(['@/pages/anniversary/GZ'], resolve)
    },
    {
      path: '/anniversary/app818',
      meta:{
        title: '百果园App四周年庆',
      },
      component: (resolve) => require(['@/pages/anniversary/APP818'], resolve)
    },
    {
      path: '/anniversary/appCard',
      meta:{
        title: '百果园App四周年庆',
      },
      component: (resolve) => require(['@/pages/anniversary/APP_card'], resolve)
    },
    {
      path: '/anniversary/appCoupon',
      meta:{
        title: '百果园App四周年庆',
      },
      component: (resolve) => require(['@/pages/anniversary/APP_coupon'], resolve)
    },
    {
      path: '/anniversary/APPGuide',
      meta:{
        title: '百果园App四周年庆',
      },
      component: (resolve) => require(['@/pages/anniversary/APP_dlGuide'], resolve)
    },
    {
      path: '/anniversary/fresh',
      meta:{
        title: '百果园',
      },
      component: (resolve) => require(['@/pages/anniversary/fresh'], resolve)
    },
    {
      path: '/anniversary/freshHelp',
      meta:{
        title: '百果园',
      },
      component: (resolve) => require(['@/pages/anniversary/fresh_help'], resolve)
    },
    {
      name:'mooncakeIndex',
      path: '/anniversary/mooncake',
      meta:{
        title: '百果园',
      },
      component: (resolve) => require(['@/pages/anniversary/moonCake'], resolve)
    },
    {
      path: '/anniversary/moonlist',
      meta:{
        title: '百果园',
      },
      component: (resolve) => require(['@/pages/anniversary/moonList'], resolve)
    },
    {
      path: '/anniversary/friendBargain',
      meta:{
        title: '百果心享会员2周年-0元吃猫山王',
      },
      component: (resolve) => require(['@/pages/anniversary/friendBargain'], resolve)
    },
    // {
    //     path: '/anniversary/fresh_test',
    //     meta:{
    //         title: '百果园',
    //     },
    //     component: (resolve) => require(['@/pages/anniversary/fresh'], resolve)
    // },
    // {
    //     path: '/anniversary/freshHelp_test',
    //     meta:{
    //         title: '百果园',
    //     },
    //     component: (resolve) => require(['@/pages/anniversary/fresh_help'], resolve)
    // }
  ]
  