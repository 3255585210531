<template>
  <div id="app" class="">
    <router-view v-wechat-title="$route.meta.title"></router-view>
    <div
      class="share-mask"
      :class="{ visible: $store.state.isShowShareMask }"
      @click="$store.commit('IS_SHOW_SHARE_MASK', false)"
    >
      <img
        :class="{ imgMini: $store.state.isMiniPage }"
        src="https://jxstatic-1251596386.cos.ap-guangzhou.myqcloud.com/Technical/h5/pagoda/invite/share-mask.png"
        alt=""
      />
    </div>
    <div id="loading-mask" class="visible">
      <div id="mySprite"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    if (
      typeof WeixinJSBridge == 'object' &&
      typeof WeixinJSBridge.invoke == 'function'
    ) {
      handleFontSize()
    } else {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    }
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on('menu:setfont', function () {
        WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
      })
    }
  }
}
</script>
<style type="text/css">
body {
  -webkit-text-size-adjust: 100% !important;
}
#loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999999;
  display: none;
}
#loading-mask.visible {
  display: block;
}
#loading-mask .loading {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
#app.app {
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  left: 0;
  top: 0;
}

#mySprite {
  background-image: url('https://jxstatic-1251596386.cos.ap-guangzhou.myqcloud.com/Technical/h5/pagoda/loading3.png');
  width: 100px;
  height: 100px;
  animation: loading11162 3.6s steps(1) infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: scale3d(0.5, 0.5, 0.5);
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
}

@keyframes loading11162 {
  0% {
    background-position: 0px 0px;
  }
  1.12% {
    background-position: -100px 0px;
  }
  2.25% {
    background-position: -200px 0px;
  }
  3.37% {
    background-position: -300px 0px;
  }
  4.49% {
    background-position: -400px 0px;
  }
  5.62% {
    background-position: -500px 0px;
  }
  6.74% {
    background-position: -600px 0px;
  }
  7.87% {
    background-position: -700px 0px;
  }
  8.99% {
    background-position: -800px 0px;
  }
  10.11% {
    background-position: -900px 0px;
  }
  11.24% {
    background-position: -1000px 0px;
  }
  12.36% {
    background-position: -1100px 0px;
  }
  13.48% {
    background-position: -1200px 0px;
  }
  14.61% {
    background-position: -1300px 0px;
  }
  15.73% {
    background-position: -1400px 0px;
  }
  16.85% {
    background-position: -1500px 0px;
  }
  17.98% {
    background-position: -1600px 0px;
  }
  19.10% {
    background-position: -1700px 0px;
  }
  20.22% {
    background-position: -1800px 0px;
  }
  21.35% {
    background-position: -1900px 0px;
  }
  22.47% {
    background-position: -2000px 0px;
  }
  23.60% {
    background-position: -2100px 0px;
  }
  24.72% {
    background-position: -2200px 0px;
  }
  25.84% {
    background-position: -2300px 0px;
  }
  26.97% {
    background-position: -2400px 0px;
  }
  28.09% {
    background-position: -2500px 0px;
  }
  29.21% {
    background-position: -2600px 0px;
  }
  30.34% {
    background-position: -2700px 0px;
  }
  31.46% {
    background-position: -2800px 0px;
  }
  32.58% {
    background-position: -2900px 0px;
  }
  33.71% {
    background-position: -3000px 0px;
  }
  34.83% {
    background-position: -3100px 0px;
  }
  35.96% {
    background-position: -3200px 0px;
  }
  37.08% {
    background-position: -3300px 0px;
  }
  38.20% {
    background-position: -3400px 0px;
  }
  39.33% {
    background-position: -3500px 0px;
  }
  40.45% {
    background-position: -3600px 0px;
  }
  41.57% {
    background-position: -3700px 0px;
  }
  42.70% {
    background-position: -3800px 0px;
  }
  43.82% {
    background-position: -3900px 0px;
  }
  44.94% {
    background-position: -4000px 0px;
  }
  46.07% {
    background-position: -4100px 0px;
  }
  47.19% {
    background-position: -4200px 0px;
  }
  48.31% {
    background-position: -4300px 0px;
  }
  49.44% {
    background-position: -4400px 0px;
  }
  50.56% {
    background-position: -4500px 0px;
  }
  51.69% {
    background-position: -4600px 0px;
  }
  52.81% {
    background-position: -4700px 0px;
  }
  53.93% {
    background-position: -4800px 0px;
  }
  55.06% {
    background-position: -4900px 0px;
  }
  56.18% {
    background-position: -5000px 0px;
  }
  57.30% {
    background-position: -5100px 0px;
  }
  58.43% {
    background-position: -5200px 0px;
  }
  59.55% {
    background-position: -5300px 0px;
  }
  60.67% {
    background-position: -5400px 0px;
  }
  61.80% {
    background-position: -5500px 0px;
  }
  62.92% {
    background-position: -5600px 0px;
  }
  64.04% {
    background-position: -5700px 0px;
  }
  65.17% {
    background-position: -5800px 0px;
  }
  66.29% {
    background-position: -5900px 0px;
  }
  67.42% {
    background-position: -6000px 0px;
  }
  68.54% {
    background-position: -6100px 0px;
  }
  69.66% {
    background-position: -6200px 0px;
  }
  70.79% {
    background-position: -6300px 0px;
  }
  71.91% {
    background-position: -6400px 0px;
  }
  73.03% {
    background-position: -6500px 0px;
  }
  74.16% {
    background-position: -6600px 0px;
  }
  75.28% {
    background-position: -6700px 0px;
  }
  76.40% {
    background-position: -6800px 0px;
  }
  77.53% {
    background-position: -6900px 0px;
  }
  78.65% {
    background-position: -7000px 0px;
  }
  79.78% {
    background-position: -7100px 0px;
  }
  80.90% {
    background-position: -7200px 0px;
  }
  82.02% {
    background-position: -7300px 0px;
  }
  83.15% {
    background-position: -7400px 0px;
  }
  84.27% {
    background-position: -7500px 0px;
  }
  85.39% {
    background-position: -7600px 0px;
  }
  86.52% {
    background-position: -7700px 0px;
  }
  87.64% {
    background-position: -7800px 0px;
  }
  88.76% {
    background-position: -7900px 0px;
  }
  89.89% {
    background-position: -8000px 0px;
  }
  91.01% {
    background-position: -8100px 0px;
  }
  92.13% {
    background-position: -8200px 0px;
  }
  93.26% {
    background-position: -8300px 0px;
  }
  94.38% {
    background-position: -8400px 0px;
  }
  95.51% {
    background-position: -8500px 0px;
  }
  96.63% {
    background-position: -8600px 0px;
  }
  97.75% {
    background-position: -8700px 0px;
  }
  98.88% {
    background-position: -8800px 0px;
  }
  100.00% {
    background-position: -8900px 0px;
  }
}
</style>
