import $axios from '@/utils/http'
import Base from './base'

export default class midFes extends Base {
    submit (params ) {
        return $axios.post(`${this.baseURL}/h5/marketing/addAddress`, params)
    }
    luck (params ) {
        return $axios.postCrypto(`${this.baseURL}/h5/marketing/luck`, params)
    }
    setShare (params ) {
        return $axios.post(`${this.baseURL}/h5/marketing/share`, params)
    }
    getRecordList (params ) {
        return $axios.post(`${this.baseURL}/h5/marketing/record`, params)
    }
    getInfo (params ) {
        return $axios.post(`${this.baseURL}/h5/marketing/info`, params)
    }
    getBroadcastList (params ) {
        return $axios.post(`${this.baseURL}/h5/marketing/broadcast`, params)
    }
} 