import $axios from '@/utils/http'
import Base from './base'

export default class pullNew extends Base {
  setPosterGrant ( params ) {
    return $axios.post(`${this.baseURL}/poster/grant`, params)
  }
  setMemberGeneralize ( params ) {
    return $axios.post(`${this.baseURL}/member/generalize`, params)
  }
  getInfo ( params ) {
    return $axios.post(`${this.baseURL}/generalize/info`, params)
  }
  getGeneralizePoster ( params ) {
    return $axios.post(`${this.baseURL}/generalize/poster`, params)
  }
}