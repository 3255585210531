import $axios from '@/utils/http'
import Base from './base'

export default class SurpriseEveryDay extends Base {
  // 获取门店名称
  getStoreInfo ( params ) {
    return $axios.post(`${this.baseURL}/packet/storeInfo`, params)
  }
  // 生成红包 
  submit ( params ) { 
    return $axios.post(`${this.baseURL}/packet/setting`, params)
  }
  // 获取红包状态
  getPacketState( params ) {
    return $axios.post(`${this.baseURL}/packet/drawInfo`, params)
  }
  // 开启红包
  open ( params ) {
    return $axios.post(`${this.baseURL}/packet/open`, params)
  }
  // 提现
  cashOut( params ) {
    return $axios.post(`${this.baseURL}/packet/extract`, params)
  }
  // 获取红包记录
  getPacketRecord ( params ) {
    return $axios.post(`${this.baseURL}/packet/myRecord`, params)
  }
  // 获取红包详情
  getPacketDetails ( params ) {
    return $axios.post(`${this.baseURL}/packet/detail`, params)
  }
  // 设置配置
  setConfig ( params ) {
    return $axios.post(`${this.baseURL}/packet/settingConfig`, params)
  }
  // 当天是否设置过红包/api/packet/settingPackageInfoDay
  setPackageInfo( params ) {
    return $axios.post(`${this.baseURL}/packet/settingPackageInfoDay`, params)
  }
  // 获取红包列表
  getPacketList (params) {
    return $axios.post(`${this.baseURL}/packet/setList`, params)
  }
  // 获取配置页红包信息
  getRedPacketConfig ( params ) {
    return $axios.post(`${this.baseURL}/packet/redPacketConfig`, params)
  }
  // 获取门店推荐商品
  getStoreRecommend ( params ) {
    return $axios.post(`${this.baseURL}/goods/storeRecommend`, params)
  }
}
