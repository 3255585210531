import $axios from '@/utils/http'
import Base from './base'

export default class anniversary extends Base {
  // 获取页面初始信息 
  getInit (params ) {
    return $axios.post(`${this.baseURL}/anniversary/init`, params)
  }
  // 领券
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/anniversary/receive`, params)
  }
  // 获取商品信息
  getGoodInfo (params ) {
    return $axios.post(`${this.baseURL}/goods/detail`, params)
  }
  // ----- 自营 818 start----------
  // 基础信息
  anniLuckBaseInfo (params) {
    return $axios.post(`${this.baseURL}/quadrennial/info`, params)
  }
  // 板块配置
  anniSectionConfig (params) {
    return $axios.post(`${this.baseURL}/quadrennial/config`, params)
  }
  // 抽奖
  anniLuck (params) {
    return $axios.postCrypto(`${this.baseURL}/quadrennial/luck`, params)
  }
  // 抽奖记录
  anniLuckRecord (params) {
    return $axios.post(`${this.baseURL}/quadrennial/record`, params)
  }
  // 分享
  anniShare (params) {
    return $axios.post(`${this.baseURL}/quadrennial/share`, params)
  }
  // 集卡 助力
  anniCardHelp (params) {
    return $axios.postCrypto(`${this.baseURL}/quadrennial/card/help`, params)
  }
  // 集卡 建立邀请关系
  anniBuildRelative (params) {
    return $axios.post(`${this.baseURL}/quadrennial/build/relation`, params)
  }
  // 集卡 卡片基础信息
  anniCardCollectingInfo (params) {
    return $axios.post(`${this.baseURL}/quadrennial/card/info`, params)
  }
  // 集卡 合成
  anniCardSynthesis (params) {
    return $axios.post(`${this.baseURL}/quadrennial/card/synthesis`, params)
  }
  // 集卡 助力明细
  anniCardRecord (params) {
    return $axios.post(`${this.baseURL}/quadrennial/card/record`, params)
  }
  // 集卡 中奖信息
  anniCardprizeInfo (params) {
    return $axios.post(`${this.baseURL}/quadrennial/card/prizeInfo`, params)
  }

  // 仅限app 领取
  anniReceive (params) {
    return $axios.postCrypto(`${this.baseURL}/quadrennial/receive`, params)
  }
  // 仅限app  是否领取
  anniReceiveInfo (params) {
    return $axios.post(`${this.baseURL}/quadrennial/receiveInfo`, params)
  }
  // 城市券配置信息
  anniCityCanServe (params) {
    return $axios.post(`${this.baseURL}/quadrennial/cityCanServe`, params)
  }
  // 15-18抽中榴莲信息
  anniDurianRecord (params) {
    return $axios.post(`${this.baseURL}/quadrennial/durian/record`, params)
  }

  // ----- 自营 818 end------------
//-----------------------------------------------------2020 素生鲜 99周年 start---------------------------------------------------------
  // 配置信息
  freshConfig (params) {
    return $axios.post(`${this.baseURL}/doubleNine/config`, params)
  }
  // 参与信息
  freshInfo (params) {
    return $axios.post(`${this.baseURL}/doubleNine/info`, params)
  }
  // 领券
  freshReceive ( params ){
    return $axios.postCrypto(`${this.baseURL}/doubleNine/receive`, params)
  }
  // 助力
  freshHelp ( params ){
    return $axios.postCrypto(`${this.baseURL}/doubleNine/help`, params)    
  }
  // 免单
  rewardList ( params ){
    return $axios.post(`${this.baseURL}/doubleNine/rewardList`, params)    
  }
  //-----------------------------------------------------2020 素生鲜 99周年 end  ---------------------------------------------------------
  //-----------------------------------------------------素生鲜 中秋 start-------------------------------------------------------------------
  // 每天领取 卡
  moonCollectCard ( params ){
    return $axios.postCrypto(`${this.baseURL}/xxMid/collect`, params)    
  }
  // 赠送
  moonGiveCard ( params ){
    return $axios.postCrypto(`${this.baseURL}/xxMid/give`, params)
  }
  // 领取
  moonReceiveCard ( params ){
    return $axios.postCrypto(`${this.baseURL}/xxMid/receive`, params)
  }
  // 卡数量信息
  moonCardInfo ( params ){
    return $axios.post(`${this.baseURL}/xxMid/cardInfo`, params)
  }
  // 索要 后赠送
  moonAskForGive ( params ){
    return $axios.postCrypto(`${this.baseURL}/xxMid/askForGive`, params)
  }
  // 兑换福袋
  moonExchange ( params ){
    return $axios.postCrypto(`${this.baseURL}/xxMid/exchange`, params)
  }
  // 兑换明细
  moonRecord ( params ){
    return $axios.post(`${this.baseURL}/xxMid/record`, params)
  }
  // 填写地址信息 
  moonAddress ( params ){
    return $axios.post(`${this.baseURL}/xxMid/address`, params)
  }
  // 分享
  moonShare ( params ){
    return $axios.post(`${this.baseURL}/xxMid/share`, params)  
  }
  // 广播数据
  moonBroadcast ( params ){
    return $axios.post(`${this.baseURL}/xxMid/info`, params)  
  }
  //-----------------------------------------------------素生鲜 中秋 end-------------------------------------------------------------------
  //-----------------------------------------------------心享2周年砍榴莲 start--------------------------------------------------------------
  // 获取基础信息
  // /api/durian/bargaining/info
  getBargainInfo ( params ) {
    return $axios.post(`${this.baseURL}/durian/bargaining/info`, params)  
  }
  // 砍价
  bargain ( params ) {
    return $axios.postCrypto(`${this.baseURL}/durian/bargaining/do`, params)
  }
  // 生成分享id
  getShareId ( params ) {
    return $axios.post(`${this.baseURL}/durian/bargaining/share`, params)  
  }
  // 获取榴莲成功的记录
  getFinishRecord ( params ) {
    return $axios.post(`${this.baseURL}/durian/bargaining/finishRecord`, params)
  }
  // 获取砍价记录
  getBargainRecord ( params ) {
    return $axios.post(`${this.baseURL}/durian/bargaining/record`, params)  
  }
  //-----------------------------------------------------心享2周年砍榴莲 end----------------------------------------------------------------
}
