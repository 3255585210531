export default class Base {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_BASE
    this.fastDFSURL =
      process.env.VUE_APP_ENV === 'prod'
        ? 'https://fastdfs-uploading.pagoda.com.cn/file/common/commonUpload'
        : 'https://fastdfs-uploading.test.pagoda.com.cn/file/common/commonUpload'
    this.baseSlrURL = process.env.VUE_APP_SLR_BASE
  }
}
