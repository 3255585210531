export default [
    {
      path: '/marketingPoster/index',
      meta:{
        title: '百果园'
      },
      component: (resolve) => require(['@/pages/marketingPoster/index'], resolve)
    },
    {
        path: '/marketingPoster/fission',
        meta:{
            title: '百果园'
        },
        component: (resolve) => require(['@/pages/marketingGrant/index'], resolve)
    }
  ]
  