export default [
  {
      name: 'memberIndex',
      path: '/member/index',
       meta:{
          title: '百果心享会员',
          screenName:'百果心享会员页',
          screenCode:'3009'
      },
      component: (resolve) => require(['@/pages/member/index_v5.0'], resolve)
      // component: (resolve) => require(['@/pages/member/index'], resolve)
  },
  {
      path: '/member/couponList',
      meta:{
          title: '月度礼包券',
          screenName:'月度礼包券',
          screenCode:'3017'
      },
      // component: (resolve) => require(['@/pages/member/couponList'], resolve)
      component: (resolve) => require(['@/pages/member/giftCouponList_v2'], resolve)
  },
  {
      path: '/member/historyBagInfo',
      meta:{
          title: '历史礼包券'
      },
      component: (resolve) => require(['@/pages/member/historyBagInfo'], resolve)
  },
  {
      path: '/member/memberList',
      meta:{
          title: '节省记录',
          screenName:'节省记录页',
          screenCode:'3010'
      },
      // component: (resolve) => require(['@/pages/member/memberList'], resolve) 
      component: (resolve) => require(['@/pages/member/memberList_v3'], resolve)
  },
  {
      path: '/member/memberBuy',
      meta:{
          title: '',
          screenName:'百果心享购买页',
          screenCode:'3011'
      },
      // component: (resolve) => require(['@/pages/member/memberBuy'], resolve)
      // component: (resolve) => require(['@/pages/member/memberBuy_v2'], resolve)
      component: (resolve) => require(['@/pages/member/index_v5.0'], resolve)
  },
  {
      path: '/member/memberBuyFH',
      meta:{
          title: '百果心享购买'
      },
      component: (resolve) => require(['@/pages/member/memberBuy_FH'], resolve)
  },
  {
      path: '/member/memberLoading',
      meta:{
          title: '开通心享会员'
      },
      component: (resolve) => require(['@/pages/member/memberLoading'], resolve)
  },
  {
      path: '/member/autoRenew',
      meta:{
          title: ''
      },
      component: (resolve) => require(['@/pages/member/openManagement'], resolve)
  },
  {
      path: '/member/autoRenewLoading',
      meta:{
          title: '管理自动续费'
      },
      component: (resolve) => require(['@/pages/member/autoRenewLoading'], resolve)
  },
  {
      path: '/member/privilege',
      meta:{
          title: '心享权益细则',
          isWeb:true,
          screenName:'心享权益细则',
          screenCode:'3014'
      },
      component: (resolve) => require(['@/pages/member/privilege_v2'], resolve)
  },
  // 试用会员的细则
  {
      path: '/member/privilegeT',
      meta:{
          title: '心享权益细则',
          isWeb:true
      },
      component: (resolve) => require(['@/pages/member/privilege_t'], resolve)
  },
  {
      path: '/member/certification',
      meta:{
          title: '实名认证'
      },
      component: (resolve) => require(['@/pages/member/certification'], resolve)
  },
  {
      path: '/member/searchAddress',
      meta:{
          title: '开通门店'
      },
      component: (resolve) => require(['@/pages/member/searchAddress'], resolve)
  },
  {
      path: '/member/memberInvite',
      meta:{
          title: '心享会员邀请有礼'
      },
      component: (resolve) => require(['@/pages/member/memberInvite'], resolve)
  },
  {
      path: '/member/memberInviteImg',
      meta:{
          title: '邀请有礼'
      },
      component: (resolve) => require(['@/pages/member/memberInviteImg'], resolve)
  },
  {
      path: '/member/memberInviteList',
      meta:{
          title: '我的邀请记录'
      },
      component: (resolve) => require(['@/pages/member/memberInviteList'], resolve)
  },
  {
      path: '/member/wxMemberExchange',
      meta:{
          title: '百果心享会员兑换',
          screenName:'心享兑换页',
          screenCode:'3024'
      },
      component: (resolve) => require(['@/pages/member/wxMemberExchange'], resolve)
  },
  {
      path: '/member/memberSuccess',
      meta:{
          title: '百果心享',
          screenName:'心享购买成功页',
          screenCode:'3018'
      },
      component: (resolve) => require(['@/pages/member/memberSuccess'], resolve)
  },
  {
      path: '/member/lowerMemberSuccess',
      meta:{
          title: '百果心享',
          screenName:'心享兑换成功页',
          screenCode:'3019'
      },
      component: (resolve) => require(['@/pages/member/lowerMemberSuccess'], resolve)
  },
  {
      path: '/member/openManagement',
      meta:{
          title: '',
          screenName:'开通记录页',
          screenCode:'3015'
      },
      component: (resolve) => require(['@/pages/member/openManagement'], resolve)
  },
  {
      path: '/member/noPayFH',
      meta:{
          title: '百果心享开通',
          screenName:'飞享月卡购买页',
          screenCode:'3012'
      },
      component: (resolve) => require(['@/pages/member/noPayFH'], resolve)
  },
  // 客服渠道 购买页 门店为空
  {
      path: '/member/memberBuyCS',
      meta:{
          title: ''
      },
      component: (resolve) => require(['@/pages/member/memberBuy_CS'], resolve)
  },
  {
      path: '/member/inviteTrial',
      meta:{
          title: '邀请有礼',
          screenName:'心享会员邀请有礼页',
          screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/member/inviteTrial/index'], resolve)
  },
  {
      path: '/member/inviteDetail',
      meta:{
          title: '已成功邀请'
      },
      component: (resolve) => require(['@/pages/member/inviteTrial/detail'], resolve)
  },
  {
      path: '/member/inviteTrialRule',
      meta:{
          title: '活动规则'
      },
      component: (resolve) => require(['@/pages/member/inviteTrial/rule'], resolve)
  },
  {
      path: '/member/inviteTrialShare',
      meta:{
          title: '百果心享会员',
          filterateApi:true
      },
      component: (resolve) => require(['@/pages/member/inviteTrial/share'], resolve)
  },
  {
      path: '/member/savingInstructions',
      meta:{
          title: '省钱说明'
      },
      component: (resolve) => require(['@/pages/member/savingInstructions'], resolve)
  },
  {
      path: '/h5/sfvipSignUp',
      meta:{
        title: '招牌果产地游',
        screenName:'招牌果产地游',
        screenCode:'H5活动'
      },
      component: (resolve) => require(['@/pages/member/sfvipSignUp/index'], resolve)
    },
]
