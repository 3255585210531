import $axios from '@/utils/http'
import Base from './base'

export default class electronic extends Base {
    // 查询电子账户状态
    getElectronicInfo ( params,path  ) {
        return $axios.post(`${this.baseURL}${path}/electronic/info`, params)
    }
    // 开通电子账户/快捷支付签约
    electronicCreate ( params,path ) {
        return $axios.post(`${this.baseURL}${path}/electronic/create`, params)
    }
    // 查询电子账户4要素信息
    getElectronicIdentityInfo ( params,path ) {
        return $axios.post(`${this.baseURL}${path}/electronic/identityInfo`, params)
    }
    // 获取电子账户免密支付签约验证码
    getElectronicMessageAuthCode ( params,path ) {
        return $axios.post(`${this.baseURL}${path}/electronic/messageAuthCode`, params)
    }
    // 查询账户绑定卡结果
    getElectronicCheckBindingCard ( params ) {
        return $axios.post(`${this.baseURL}/electronic/checkBindingCard`, params)
    }
    // 修改账户绑定卡
    changeBindingCard ( params ) {
        return $axios.post(`${this.baseURL}/electronic/changeBindingCard`, params)
    }
    // 电子账户开户结果查询
    getElectronicCreateResult ( params,path ) {
        return $axios.post(`${this.baseURL}${path}/electronic/createResult`, params)
    }
    //  获取当前用户可参加的充值活动
    getRechargeActivityList ( params ) {
        return $axios.post(`${this.baseURL}/recharge/activityList`, params)
    }
    //  用户充值前进行信息校验
    rechargeCheck ( params ) {
        return $axios.post(`${this.baseURL}/recharge/rechargeCheck`, params)
    }
    //  浦发web充值 送券
    payWebEA ( params ) {
        return $axios.postCrypto(`${this.baseURL}/pay/payWebEA`, params)
    }
    //  查询 支付
    getPayQuery ( params ) {
        return $axios.post(`${this.baseURL}/pay/query`, params)
    }
}
