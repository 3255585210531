import $axios from '@/utils/http'
import Base from './base'

export default class H5 extends Base {
  // ---------------------自营 端午节 Start--------------------------------------
  // 参与抽奖
  dragonFestival ( params ) {
    return $axios.post(`${this.baseURL}/dragonFestival/index`, params)
  }
  // 分享
  dragonShare ( params ) {
    return $axios.post(`${this.baseURL}/dragonFestival/share`, params)
  }
  // 查询基础信息
  dragonInfo ( params ) {
    return $axios.post(`${this.baseURL}/dragonFestival/info`, params)
  }
  // 获取商品
  getGoodsConf ( params ) {
    return $axios.post(`${this.baseURL}/dragon/goodsInfo`, params)
  }
  // ---------------------自营 端午节 End----------------------------------------

  // -------------------79折福利 start---------------------------------
  // 79折领券
  welfareReceive ( params ) {
    return $axios.postCrypto(`${this.baseURL}/microOpen/receive`, params)
  }
  welfareInfo ( params ) {
    return $axios.post(`${this.baseURL}/microOpen/info`, params)
  }
  // -------------------79折福利 end-----------------------------------

  // -------------------国民水果日 start-----------------------------------
  getNaFruitFesInfo ( params ) {
    return $axios.post(`${this.baseURL}/h5/naFruitFes/info`, params)
  }

  naFruitFesReceive ( params ) {
    return $axios.postCrypto(`${this.baseURL}/h5/naFruitFes/receive`, params)
  }

  getNaFruitFesInviteList ( params ) {
    return $axios.post(`${this.baseURL}/h5/naFruitFes/record`, params)
  }

  naFruitFesWebLogin ( params ) {
    return $axios.post(`${this.baseURL}/tt/login`, params)
  }
  // -------------------国民水果日 end-----------------------------------

  // -------------------心享2周年红包雨 start-----------------------------------
  getRedPacketRainInfo ( params ) {
    return $axios.post(`${this.baseURL}/svl/info`, params)
  }

  redPacketRainDraw ( params ) {
    return $axios.postCrypto(`${this.baseURL}/svl/luck`, params)
  }

  getRedPacketRainPrizeList ( params ) {
    return $axios.post(`${this.baseURL}/svl/record`, params)
  }

  setRedPacketRainShare ( params ) {
    return $axios.post(`${this.baseURL}/svl/share`, params)
  }
  // -------------------心享2周年红包雨 end-----------------------------------

  // -------------------国民素生鲜节 start-----------------------------------
  // 券基本信息
  getCouponInfo ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/info`, params)
  }
  // 领券
  receiveCoupon ( params ) {
    return $axios.postCrypto(`${this.baseURL}/de/pf/receive`, params)
  }
  // 抽奖
  luckDraw ( params ) {
    return $axios.postCrypto(`${this.baseURL}/de/pf/luck`, params)
  }
  // 分享加一次
  getShareInfo ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/share`, params)
  }
  // 添加地址
  addAddress ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/address`, params)
  }
  // 生成活动id
  getActivityId ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/actId`, params)
  }
  // 助力页面获取信息
  getActDetail ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/actDetail`, params)
  }
  // 助力
  assist ( params ) {
    return $axios.postCrypto(`${this.baseURL}/de/pf/assist`, params)
  }
  // 获取抽奖记录
  getRecord ( params ) {
    return $axios.post(`${this.baseURL}/de/pf/record`, params)
  }
  // -------------------国民素生鲜节 end-----------------------------------

  // -------------------自营双十一 start-----------------------------------
  getDoubleElevenInfo ( params ) {
    return $axios.post(`${this.baseURL}/de/info`, params)
  }

  doubleElevenReceive ( params ) {
    return $axios.postCrypto(`${this.baseURL}/de/receive`, params)
  }

  getDoubleElevenInviteList ( params ) {
    return $axios.post(`${this.baseURL}/de/record`, params)
  }
  // -------------------自营双十一 end-----------------------------------

  // ------------------- 大生鲜 常驻抽奖 start ---------------------------
  getCashDrawInfo ( params ) {
    return $axios.post(`${this.baseURL}/h5/ff/info`, params)
  }
  cashDrawLuck ( params ) {
    return $axios.post(`${this.baseURL}/h5/ff/luck`, params)
  }
  cashDrawShare ( params ) {
    return $axios.post(`${this.baseURL}/h5/ff/share`, params)
  }
  // ------------------- 大生鲜 常驻抽奖 end -----------------------------
  // 查询门店
  getStoreNam ( params ) {
    return $axios.post(`${this.baseURL}/research/getStoreName`, params)
  }
  // -------------------双十二国民素生鲜节 start-----------------------------------
  // 获取会员信息
  getDtVipInfo ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/isSuperVip`, params)
  }
  // 抽奖
  dtLuckDraw ( params ) {
    return $axios.postCrypto(`${this.baseURL}/dt/pf/luck`, params)
  }
  // 分享加一次
  getDtShareInfo ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/share`, params)
  }
  // 添加地址
  dtAddAddress ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/address`, params)
  }
  // 生成活动id
  getDtActivityId ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/actId`, params)
  }
  // 助力页面获取信息
  getDtActDetail ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/actDetail`, params)
  }
  // 助力
  dtAssist ( params ) {
    return $axios.postCrypto(`${this.baseURL}/dt/pf/assist`, params)
  }
  // 获取抽奖记录
  getDtRecord ( params ) {
    return $axios.post(`${this.baseURL}/dt/pf/record`, params)
  }
  // -------------------双十二国民素生鲜节 end-----------------------------------
  // -------------------自营双十二 start-----------------------------------
  // 基本信息
  getDoubleTwelveInfo ( params ) {
    return $axios.post(`${this.baseURL}/dt/info`, params)
  }
  // 领券
  doubleTwelveReceive ( params ) {
    return $axios.postCrypto(`${this.baseURL}/dt/receive`, params)
  }
  // 提现
  doubleTwelveExtract ( params ) {
    return $axios.post(`${this.baseURL}/dt/extract`, params)
  }
  // 当前果币值
  getDoubleTwelveFruitCoinInfo ( params ) {
    return $axios.post(`${this.baseURL}/dt/fruitCoinInfo`, params)
  }
  // 个人记录
  getDoubleTwelveRecord ( params ) {
    return $axios.post(`${this.baseURL}/dt/record`, params)
  }
  // 排行
  getDoubleTwelveRanking ( params ) {
    return $axios.post(`${this.baseURL}/dt/ranking`, params)
  }
  // -------------------自营双十一 end-----------------------------------

  // -------------------社群拉新 start-----------------------------------
  communityNewCustomerStatus ( params ) {
    return $axios.postCrypto(`${this.baseURL}/h5/community/info`, params)
  }
  communityNewCustomerReceive( params ) {
    return $axios.postCrypto(`${this.baseURL}/h5/community/receive`, params)
  }
  // -------------------社群拉新 end-------------------------------------

  // -------------------圣诞 start---------------------------------
  //是否新客券 是否首次进入 是否完成 分享圣诞袜
  getChristmasInfo ( params ) {
    return $axios.post(`${this.baseURL}/christmas/info`, params)
  }
  //首次进入 获得三张卡
  christmasReward ( params ) {
    return $axios.post(`${this.baseURL}/christmas/reward`, params)
  }
  //助力
  christmasHelp ( params ) {
    return $axios.post(`${this.baseURL}/christmas/help`, params)
  }
  //卡片数量信息
  getChristmasCardInfo ( params ) {
    return $axios.post(`${this.baseURL}/christmas/cardInfo`, params)
  }
  //增送卡id
  getChristmasSendID ( params ) {
    return $axios.post(`${this.baseURL}/christmas/send`, params)
  }
  //好友增送领取
  christmasReceivePuzzle ( params ) {
    return $axios.post(`${this.baseURL}/christmas/receive`, params)
  }
  //别人索要给与
  christmasAskCard ( params ) {
    return $axios.post(`${this.baseURL}/christmas/askCard`, params)
  }
  //集卡完成
  christmasFinish ( params ) {
    return $axios.post(`${this.baseURL}/christmas/finish`, params)
  }
  // 分享
  christmasSetShare ( params ) {
    return $axios.post(`${this.baseURL}/christmas/share`, params)
  }
  // 1分钱苹果
  getBroadcast ( params ) {
    return $axios.post(`${this.baseURL}/christmas/record`, params)
  }
  // 卡记录
  getChristmasCardRecord ( params ) {
    return $axios.post(`${this.baseURL}/christmas/cardRecord`, params)
  }
  // 25号抽奖
  christmasLuck ( params ) {
    return $axios.post(`${this.baseURL}/christmas/luck`, params)
  }
  // -------------------圣诞 end-----------------------------------
  // -------------------过年就好吃好的 start---------------------------------
  //初始化信息 y
  getCCInfo ( params ) {
    return $axios.post(`${this.baseURL}/nyear/info`, params)
  }
  // 综合专题领取状态 y
  getTopicsInfo ( params ) {
    return $axios.post(`${this.baseURL}/nyear/topicsInfo`, params)
  }
  //卡信息 y
  getCollectCardInfo ( params ) {
    return $axios.post(`${this.baseURL}/nyear/cardInfo`, params)
  }
  // 卡记录 y
  getCollectCardRecord ( params ) {
    return $axios.post(`${this.baseURL}/nyear/record`, params)
  }
  // 广播
  getCollectCardBroadcast ( params ) {
    return $axios.post(`${this.baseURL}/nyear/recordList`, params)
  }
  // 领取卡片奖励 y
  receiveCardPrize ( params ) {
    return $axios.post(`${this.baseURL}/nyear/exchange`, params)
  }
  // 增送卡id y
  getCollectCardSendID ( params ) {
    return $axios.post(`${this.baseURL}/nyear/send`, params)
  }
  // 助力 y
  collectCardHelp ( params ) {
    return $axios.post(`${this.baseURL}/nyear/help`, params)
  }
  // 领取赠送卡片 y
  receiveCard ( params ) {
    return $axios.post(`${this.baseURL}/nyear/receive`, params)
  }
  //首次赠送2个卡片 y
  collectCardReward ( params ) {
    return $axios.post(`${this.baseURL}/nyear/reward`, params)
  }
  //合成 y
  collectCardFinish ( params ) {
    return $axios.post(`${this.baseURL}/nyear/finish`, params)
  }
  //开启礼盒 y
  receiveGiftBox ( params ) {
    return $axios.post(`${this.baseURL}/nyear/open/gifBox`, params)
  }
  // -------------------过年就好吃好的 end-----------------------------------
  //-------------------元宵节 start-----------------------------------
  lanternFesInfo ( params ) {
    return $axios.post(`${this.baseURL}/lantern/info`, params)
  }
  lanternFesReceiveCoupon ( params ) {
    return $axios.post(`${this.baseURL}/lantern/receive`, params)
  }
  lanternFesGoods ( params ) {
    return $axios.post(`${this.baseURL}/lantern/goodsConfig`, params)
  }
  //-------------------元宵节 end-----------------------------------
  // -------------------熊猫大鲜抽奖 start-----------------------------------
  // 抽奖
  vegLotteryLuck ( params ) {
    return $axios.post(`${this.baseURL}/h5/vegetables/luck`, params)
  }
  // 分享
  vegLotteryShare ( params ) {
    return $axios.post(`${this.baseURL}/h5/vegetables/share`, params)
  }
  // 是否分享
  vegLotteryInfo ( params ) {
    return $axios.post(`${this.baseURL}/h5/vegetables/info`, params)
  }
  // 填写地址
  vegLotteryAddress ( params ) {
    return $axios.post(`${this.baseURL}/h5/vegetables/address`, params)
  }
  // 广播
  vegLotteryRecord ( params ) {
    return $axios.post(`${this.baseURL}/h5/vegetables/record`, params)
  }
  // -------------------熊猫大鲜抽奖 end-------------------------------------
  // -------------------认养一头牛 start-----------------------------------
  // 获取初始数据
  getAdoptCowData ( params ) {
    return $axios.post(`${this.baseURL}/brandDay/info`, params)
  }
  // 获取奖品数据
  getRecordList ( params ) {
    return $axios.post(`${this.baseURL}/brandDay/record`, params)
  }
  // 抽奖
  adoptCowDraw ( params ) {
    return $axios.post(`${this.baseURL}/brandDay/luck`, params)
  }
  // 分享
  adoptCowShare ( params ) {
    return $axios.post(`${this.baseURL}/brandDay/share`, params)
  }
  // 填写地址
  adoptCowAddress ( params ) {
    return $axios.post(`${this.baseURL}/brandDay/address`, params)
  }
  // -------------------认养一头牛 end-----------------------------------
  // -------------------327 start-----------------------------------
  getPromotionInfo ( params ) {
    return $axios.post(`${this.baseURL}/h5/promotion/info`, params)
  }
  getPromotionReceive ( params ) {
    return $axios.post(`${this.baseURL}/h5/promotion/receive`, params)
  }
  // 获取综合专题数据
  getSpecialDetail ( params ) {
    return $axios.post(`${this.baseURL}/special/detail`, params)
  }
  // -------------------327 end-----------------------------------
    // -------------------熊猫大鲜327 start-----------------------------------
  // 获取是否领券信息
  getCouponMsg ( params ) {
    return $axios.post(`${this.baseURL}/h5/pr/fresh/info`, params)
  }
  // 领券
  toReceiveCoupon ( params ) {
    return $axios.post(`${this.baseURL}/h5/pr/fresh/receive`, params)
  }
  // 生成活动id
  getActId ( params ) {
    return $axios.post(`${this.baseURL}/pr/actId`, params)
  }
  // 助力页面获取信息
  getHelpDetail ( params ) {
    return $axios.post(`${this.baseURL}/pr/actDetail`, params)
  }
  // 助力
  toAssist ( params ) {
    return $axios.postCrypto(`${this.baseURL}/pr/assist`, params)
  }
  // 品的数量
  getGoodsInfo ( params ) {
    return $axios.post(`${this.baseURL}/pr/goodsInfo`, params)
  }
  // -------------------熊猫大鲜327 end-----------------------------------
  // -------------------下单邀请好友赚积分 start---------------------------------
  // 获取 活动id + 历史发放积分
  getOrderActivityInfo ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/info`, params)
  }
  // 积分人数配置 + 是否领取
  getOrderActivityConfig ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/config`, params)
  }
  // 助力
  orderActivityHelp ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/help`, params)
  }
  // 领取积分
  orderActivityReceiveIntegral ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/receiveIntegral`, params)
  }
  // 邀请记录
  getOrderActivityRecord ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/record`, params)
  }
  // 消息提醒设置
  settingRemind ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/settingRemind`, params)
  }
  // 历史记录
  getHistoryRecord ( params ) {
    return $axios.postCrypto(`${this.baseURL}/order/activity/historyRecord`, params)
  }
  // -------------------下单邀请好友赚积分 end-----------------------------------
  // -------------------人客系统 start---------------------------------
  // 获取信息
  getVisitorsInfo ( params ) {
    return $axios.post(`${this.baseURL}/all/customer/info`, params)
  }
  // 领券
  receiveVisitorsCoupon ( params ) {
    return $axios.postCrypto(`${this.baseURL}/all/customer/receive`, params)
  }
  // -------------------人客系统 end-----------------------------------
}
