import $axios from '@/utils/http'
import $axiosServerless from '@/utils/httpServerless'
import Base from './base'
import axios from 'axios'

export default class Pagoda extends Base {
  // 获取秘钥 活动使用
  getActivitySign(params) {
    return $axios.post(`${this.baseURL}/activitySign/getSign`, params)
  }

  // 获取定位信息
  getLocation(params) {
    return $axios.post(`${this.baseURL}/store/checkCity`, params)
  }
  // 首页获取推荐商品
  getRecommendGoods(params) {
    return $axios.post(`${this.baseURL}/homepage/recommendGoods`, params)
  }
  // 获取商品信息
  getGoodInfo(params) {
    return $axios.post(`${this.baseURL}/goods/detail`, params)
  }
  //获取积分 + 钱包 + 券数量
  getBalance(params) {
    return $axios.post(`${this.baseURL}/customer/getBalance`, params)
  }
  // 获取配置的商品 常规配置
  getGoodsList(params) {
    return $axios.post(`${this.baseURL}/goods/configInfo`, params)
  }
  // 获取商品信息 批量
  getGoodsListInfo(params) {
    return $axios.post(`${this.baseURL}/goods/detailBatch`, params)
  }

  // 兑吧子模块跳转链接
  getDuibaJumpUrl(params) {
    return $axios.post(`${this.baseSlrURL}/duiba/activity/jump`, params)
  }
  // 获取 fastDFS签名
  getFastDFS(params) {
    return $axios.post(`${this.baseURL}/upload/access`, params)
  }
  uploadFile(params, header) {
    return axios.post(`${this.fastDFSURL}`, params, { headers: header })
  }
  // 获取用户信息
  getCustomerInfo(params) {
    return $axios.post(`${this.baseURL}/customer/info`, params)
  }
  //统一支付
  pay(params) {
    return $axios.postCrypto(`${this.baseURL}/pay/pay`, params)
  }
  // 获取验证码（中台）
  getCaptcha(params) {
    return $axios.post(`${this.baseURL}/captcha/send`, params)
  }
  //获取用户积分数(一体化)
  getIntegral(params) {
    return $axios.post(`${this.baseURL}/queryMember/account`, params)
  }
  // 获取跳转微信小程序转换地址
  getWxMiniAddress(params) {
    return $axios.post(`${this.baseURL}/jump/wxMiNi`, params)
  }
  // 接口公共方法
  commonInterface(url, params) {
    return $axios.post(`${this.baseURL}${url}`, params)
  }
  // serverless请求公共方法
  commonSlrInterface(url, params) {
    return $axios.post(`${this.baseSlrURL}${url}`, params)
  }
  // 加密接口
  cryptoInterface(url, params) {
    return $axios.postCrypto(`${this.baseURL}${url}`, params)
  }
  // 接口公共方法--serverless
  commonInterfaceServerless(url, params) {
    return $axiosServerless.post(`${this.baseSlrURL}${url}`, params)
  }
  commonInterfaceServerlessGet(url, params) {
    return $axiosServerless.get(`${this.baseSlrURL}${url}`, params)
  }
  // 上传身份证-serverless
  uploadFileServerless(url, params, header) {
    return axios.post(`${this.baseSlrURL}${url}`, params, { headers: header })
  }
}
