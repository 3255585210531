export default [
  {
    path: '/invite/index',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/index'], resolve)
  },
  {
    path: '/invite/inviterecords/:tabId',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/InviteRecords'], resolve)
  },
  {
    path: '/invite/activityrules',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/ActivityRules'], resolve)
  },
  {
    path: '/invite/rankinglist',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/RankingList'], resolve)
  },
  {
    path: '/invite/awardrecords',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/AwardRecords'], resolve)
  },
  {
    path: '/invite/facetoface',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/FaceToFace'], resolve)
  },
  {
    path: '/invite/acceptinvite',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/AcceptInvite'], resolve)
  },
  {
    path: '/invite/remind',
    meta:{
      title: '邀请有礼'
    },
    component: (resolve) => require(['@/pages/invite/Remind'], resolve)
  }
]
