import $axios from '@/utils/http'
import Base from './base'

export default class newGift extends Base {
  // 获取页面初始信息 
  getReceive (params ) {
    return $axios.post(`${this.baseURL}/newGift/receive`, params)
  }
  getRecommendGoods ( params ) {
    return $axios.post(`${this.baseURL}/homepage/recommendGoods`, params)
  }
} 