import Vue from 'vue'
import Router from 'vue-router'
import Routes from './routes'
import wx from '@pagoda-tools/jweixin'
import cookieStorage from '@/utils/cookieStorage'
import axios from 'axios'
import { getIsBindPhone, jsApiAuth } from '@/utils/wechat'
import helper from '@/utils/helper'
import Toast from 'pagoda-mobile/lib/toast'

const baseURL = process.env.VUE_APP_API_BASE
const defaultHeadImg =
  'https://jxstatic-1251596386.cos.ap-guangzhou.myqcloud.com/Technical/h5/member/icon_portrait1.png'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: Routes
})

router.beforeEach((to, from, next) => {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual'
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // document.title = to.meta.title;
    // const hackIframe = document.createElement('iframe');
    // hackIframe.style.display = 'none';
    // hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
    // document.body.appendChild(hackIframe);
    // setTimeout(() => {
    //     document.body.removeChild(hackIframe)
    // }, 0)
    // document.title = to.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    // if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //     const hackIframe = document.createElement('iframe');
    //     hackIframe.style.display = 'none';
    //     hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
    //     document.body.appendChild(hackIframe);
    //     setTimeout(_ => {
    //         document.body.removeChild(hackIframe)
    //     }, 1)
    // }
    // const hackIframe = document.createElement('iframe');
    // hackIframe.style.display = 'none';
    // hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
    // document.body.appendChild(hackIframe);
    // setTimeout(_ => {
    //     document.body.removeChild(hackIframe)
    // }, 1)
    document.title = to.meta.title
  }
  if (to.meta.common) {
    next()
    return
  }

  if (!window.appEnv) {
    ;(function (window) {
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/SAAASDK/i) == 'saaasdk') {
        window.appEnv = 'miniProgram'
        //多端app
        sessionStorage.setItem('notes', 'donutApp')
        sessionStorage.setItem('channel', 'miniProgram')
        let params = helper.getUrlParams()
        params = helper.removePrivilegeParams(to.path, params)
        if (Object.keys(params).length !== 0) {
          // sessionStorage.setItem('channel', params.channel=='pandafresh' ? 'wx_xx':'miniProgram')
          sessionStorage.setItem(
            'user_id',
            params.user_id ? params.user_id : -1
          )
          sessionStorage.setItem('user_token', params.user_token)
          sessionStorage.setItem('phoneNumber', params.phoneNumber)
          sessionStorage.setItem('city_id', params.city_id)
          sessionStorage.setItem('city_name', params.city_name)
          sessionStorage.setItem('store_id', params.store_id)
          sessionStorage.setItem(
            'store_name',
            params.store_name ? params.store_name : ''
          )
          sessionStorage.setItem(
            'headimgurl',
            params.headimgurl ? params.headimgurl : defaultHeadImg
          )
          sessionStorage.setItem(
            'nickname',
            params.nickname ? params.nickname : ''
          )
          sessionStorage.setItem('union_id', params.unionid)
          sessionStorage.setItem('version', params.version)
          sessionStorage.setItem('lat', params.lat)
          sessionStorage.setItem('lon', params.lon)
          sessionStorage.setItem('cityCode', params.cityCode) //城市编码
          sessionStorage.setItem('storeCode', params.storeCode) //门店编码
          sessionStorage.setItem(
            'deliveryCenterCode',
            params.deliveryCenterCode
          ) //配送中心编码
          sessionStorage.setItem(
            'tokenChannel',
            params.tokenChannel ? params.tokenChannel : ''
          )
          sessionStorage.setItem(
            'curLocation',
            params.curLocation ? params.curLocation : JSON.stringify({})
          ) //用户实际定位经纬度
          let jsApiUrl = window.location.href
          cookieStorage.setItem('jsApiUrl', jsApiUrl)
          let saParams = {
            Terminal: '百果园+小程序'
          }
          let keyList = [
            'wechatUnionID',
            'is_login',
            'traceId',
            'GroupNewUsers',
            'QuicklyDeliveryUsers',
            'NextDeliveryNewUsers',
            'wecomIsGuideFriend_a',
            'wecomInGroupchat_a',
            'paymemberTypeCode',
            'paymemberType',
            'paymemberIdentifyCode',
            'memberlevelId_levelName',
            '$referrer',
            'referringScreenName',
            'referringScreenCode',
            '$latest_scene'
          ]
          keyList.map((item) => {
            if (params[item]) {
              saParams[item] = params[item]
            }
          })
          localStorage.setItem('saParams', JSON.stringify(saParams))
        } else {
          let saParams = {
            Terminal: '百果园+小程序'
          }
          localStorage.setItem('saParams', JSON.stringify(saParams))
        }
        next()
      } else if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信
        wx.miniProgram.getEnv((res) => {
          // 小程序
          
          if (res.miniprogram) {
            window.appEnv = 'miniProgram'
            sessionStorage.setItem('channel', 'miniProgram')
            let params = helper.getUrlParams()
            params = helper.removePrivilegeParams(to.path, params)
            if (Object.keys(params).length !== 0) {
              sessionStorage.setItem(
                'channel',
                params.channel == 'pandafresh' ? 'wx_xx' : 'miniProgram'
              )
              sessionStorage.setItem(
                'user_id',
                params.user_id ? params.user_id : -1
              )
              sessionStorage.setItem('user_token', params.user_token)
              sessionStorage.setItem('phoneNumber', params.phoneNumber)
              sessionStorage.setItem('city_id', params.city_id)
              sessionStorage.setItem('city_name', params.city_name)
              sessionStorage.setItem('store_id', params.store_id)
              sessionStorage.setItem(
                'store_name',
                params.store_name ? params.store_name : ''
              )
              sessionStorage.setItem(
                'headimgurl',
                params.headimgurl ? params.headimgurl : defaultHeadImg
              )
              sessionStorage.setItem(
                'nickname',
                params.nickname ? params.nickname : ''
              )
              sessionStorage.setItem('union_id', params.unionid)
              sessionStorage.setItem('version', params.version)
              sessionStorage.setItem('lat', params.lat)
              sessionStorage.setItem('lon', params.lon)
              sessionStorage.setItem('cityCode', params.cityCode) //城市编码
              sessionStorage.setItem('storeCode', params.storeCode) //门店编码
              sessionStorage.setItem(
                'deliveryCenterCode',
                params.deliveryCenterCode
              ) //配送中心编码
              sessionStorage.setItem(
                'tokenChannel',
                params.tokenChannel ? params.tokenChannel : ''
              )
              sessionStorage.setItem(
                'curLocation',
                params.curLocation ? params.curLocation : JSON.stringify({})
              ) //用户实际定位经纬度
              let jsApiUrl = window.location.href
              cookieStorage.setItem('jsApiUrl', jsApiUrl)
              let saParams = {
                Terminal: '百果园+小程序'
              }
              let keyList = [
                'wechatUnionID',
                'is_login',
                'traceId',
                'GroupNewUsers',
                'QuicklyDeliveryUsers',
                'NextDeliveryNewUsers',
                'wecomIsGuideFriend_a',
                'wecomInGroupchat_a',
                'paymemberTypeCode',
                'paymemberType',
                'paymemberIdentifyCode',
                'memberlevelId_levelName',
                '$referrer',
                'referringScreenName',
                'referringScreenCode',
                '$latest_scene'
              ]
              keyList.map((item) => {
                if (params[item]) {
                  saParams[item] = params[item]
                }
              })
              localStorage.setItem('saParams', JSON.stringify(saParams))
            } else {
              let saParams = {
                Terminal: '百果园+小程序'
              }
              localStorage.setItem('saParams', JSON.stringify(saParams))
            }
            next()
          } else {
            // 非小程序
            window.appEnv = 'wx'
            let openId = cookieStorage.getItem('openId')
            let version = cookieStorage.getItem('version')
            let nickName = cookieStorage.getItem('nickname')
            let headimgurl = cookieStorage.getItem('headimgurl')
            let saParams = {
              Terminal: '百果园H5'
            }
            localStorage.setItem('saParams', JSON.stringify(saParams))
            if (!openId || !nickName || !headimgurl || version != '2.7.2') {
              axios
                .post(`${baseURL}/weChatGateway/url`, {
                  requestUrl: window.location.href
                })
                .then((res) => {
                  window.location.href = res.data.data
                })
            } else {
              getIsBindPhone().then(() => {
                cookieStorage.setItem('channel', 'wx')
                next()
              })
            }
          }
        })
      } else if (
        ua.match(/PagodaBuy/i) == 'pagodabuy' ||
        (cookieStorage.getItem('app_version') &&
          /(iPhone|iPad|iPod|iOS|Mac|Mac)/i.test(ua))
      ) {
        // App
        cookieStorage.setItem('channel', 'app')
        window.appEnv = 'app'

        if (!cookieStorage.getItem('user_head')) {
          cookieStorage.setItem('user_head', defaultHeadImg)
        }

        //兼容首次安装弹窗进入协议时cookie没有参数
        if (cookieStorage.getItem('app_version') == null) {
          cookieStorage.setItem('app_version', '4.5.2.0')
        }

        // 海报页不调用api
        if (to.meta.filterateApi) {
          next()
          return
        }

        // V3.4.8 埋点相关
        let app_v = cookieStorage.getItem('app_version').replace(/\./g, '')
        let isIOS = /(iPhone|iPad|iPod|iOS|Mac|Mac)/i.test(ua) //ios终端
        if (cookieStorage.getItem('app_version').split('.')[1] < 10) {
          app_v = app_v.slice(0, 1) + '0' + app_v.slice(1)
        }
        if (app_v >= 50400) {
          localStorage.setItem('saParams', JSON.stringify({}))
          let methodNameList = [
            'user.api.getUserInfo',
            'sensor.api.getSensorCommonInfo'
          ]
          window.callback = function (res) {
            localStorage.setItem(
              'saParams',
              JSON.stringify(
                Object.assign(
                  JSON.parse(localStorage.getItem('saParams')),
                  res.data
                )
              )
            )
          }
          methodNameList.map((item, index) => {
            let paramsJson = {
              methodName: item,
              callback: 'callback',
              partner: 'bgy'
            }
            paramsJson = JSON.stringify(paramsJson)
            if (isIOS) {
              // sendData 为iOS 已注册监听好的js方法，若非提前沟通，不可随意更改
              window.webkit.messageHandlers.sendData.postMessage(paramsJson)
            } else {
              // 调用安卓原生方法
              AndroidNative.call(paramsJson)
            }
          })
        } else {
          let saParams = {
            isLogin: cookieStorage.getItem('user_id') > 0 ? '是' : '否',
            distinct_id: cookieStorage.getItem('user_id'),
            Terminal: '百果园APP'
          }
          localStorage.setItem('saParams', JSON.stringify(saParams))
        }
        // V3.4.8 埋点相关 end
        next()
      } else {
        // Web
        if (to.meta.isWeb) {
          // cookieStorage.setItem('channel', 'h5_web')

          let params = helper.getUrlParams()
          params = helper.removePrivilegeParams(to.path, params)
          if (Object.keys(params).length !== 0 && params.tokenChannel) {
            window.appEnv = params.tokenChannel
            cookieStorage.setItem(
              'user_id',
              params.user_id ? params.user_id : -1
            )
            cookieStorage.setItem('user_token', params.user_token)
            cookieStorage.setItem('phoneNumber', params.phoneNumber)
            cookieStorage.setItem('city_id', params.city_id)
            cookieStorage.setItem('city_name', params.city_name)
            cookieStorage.setItem('store_id', params.store_id)
            cookieStorage.setItem(
              'store_name',
              params.store_name ? params.store_name : ''
            )
            cookieStorage.setItem(
              'headimgurl',
              params.headimgurl ? params.headimgurl : ''
            )
            cookieStorage.setItem(
              'nickname',
              params.nickname ? params.nickname : ''
            )
            // cookieStorage.setItem('union_id', params.unionid)
            cookieStorage.setItem('version', params.version)
            cookieStorage.setItem('lat', params.lat)
            cookieStorage.setItem('lon', params.lon)
            cookieStorage.setItem('cityCode', params.cityCode) //城市编码
            cookieStorage.setItem('storeCode', params.storeCode) //门店编码
            cookieStorage.setItem(
              'deliveryCenterCode',
              params.deliveryCenterCode
            ) //配送中心编码
            cookieStorage.setItem(
              'tokenChannel',
              params.tokenChannel ? params.tokenChannel : ''
            )
            let jsApiUrl = window.location.href
            cookieStorage.setItem('jsApiUrl', jsApiUrl)
          } else {
            window.appEnv = 'web'
          }
          next()
          return
        } else {
          Toast('请在微信中打开')
        }
      }
    })(window)
  } else {
    // next()
    if (window.appEnv) {
      if (window.appEnv === 'wx') {
        jsApiAuth().then(() => {
          next()
        })
      } else {
        next()
      }
    } else {
      Toast('请在微信中打开')
    }
  }
})

router.afterEach((to) => {
  document.body.scrollTop = 0
  window.pageYOffset = 0
  document.documentElement.scrollTop = 0
  // 从路由的元信息中获取 title 属性
  if (to.meta.title) {
    document.title = to.meta.title
    //       // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    //       // if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    //       //     const hackIframe = document.createElement('iframe');
    //       //     hackIframe.style.display = 'none';
    //       //     hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
    //       //     document.body.appendChild(hackIframe);
    //       //     setTimeout(_ => {
    //       //         document.body.removeChild(hackIframe)
    //       //     }, 1)
    //       // }
    const hackIframe = document.createElement('iframe')
    hackIframe.style.display = 'none'
    hackIframe.src = `/static/html/fixIosTitle.html?r=${Math.random()}`
    document.body.appendChild(hackIframe)
    setTimeout(() => {
      document.body.removeChild(hackIframe)
    }, 0)
  }
})

export default router
