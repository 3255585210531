import wx from '@pagoda-tools/jweixin'
import Toast from 'pagoda-mobile/lib/toast'
import Dialog from 'pagoda-mobile/lib/dialog'
import axios from 'axios'
import jsonp from 'jsonp'
import cookieStorage from '@/utils/cookieStorage'

const baseURL = process.env.VUE_APP_API_BASE

// 获取微信jsApi授权
function getWxJsApiAuth() {
  let url = window.location.href
  let header = {}
  header['x-defined-appinfo'] = JSON.stringify({
    channel: 'wx',
    openId: cookieStorage.getItem('openId') || ''
  })
  header['userToken'] = ''
  wx.miniProgram.getEnv((res) => {
    if (res.miniprogram) {
      // 小程序
      let ua = navigator.userAgent.toLowerCase()
      if (/iphone|ipad|ipod/.test(ua)) {
        url = cookieStorage.getItem('jsApiUrl')
      }
    }
  })

  return new Promise(function (resolve) {
    axios
      .post(
        `${baseURL}/wx/jsApiAuth`,
        { requestUrl: encodeURIComponent(url.split('#')[0]) },
        {
          headers: header
        }
      )
      .then((res) => {
        wx.config({
          debug: false,
          appId: res.data.data.appId,
          nonceStr: res.data.data.nonceStr,
          signature: res.data.data.signature,
          timestamp: res.data.data.timestamp,
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'getLocation',
            'openLocation',
            'hideMenuItems'
          ],
          openTagList: ['wx-open-launch-app', 'wx-open-launch-weapp']
        })
        resolve()
      })
  })
}
// 获取微信的定位
function getWechatLocation() {
  return new Promise(function (resolve) {
    wx.ready(function () {
      wx.getLocation({
        type: 'wgs84',
        success(wechatLocation) {
          resolve(wechatLocation)
        },
        fail: function (res) {
          Toast('获取定位异常\n' + JSON.stringify(res))
          resolve({ longitude: '', latitude: '' })
        },
        cancel: function (res) {
          Dialog.alert({
            message: '请先授权当前定位'
          }).then(() => {
            // on close
          })
          resolve({ longitude: '', latitude: '' })
        }
      })
    })
  })
}
// 获取百度的定位
function getBaiduLocation(wechatLocation) {
  return new Promise(function (resolve) {
    let { longitude, latitude } = wechatLocation
    jsonp(
      'https://api.map.baidu.com/geoconv/v1/?coords=' +
        longitude +
        ',' +
        latitude +
        '&from=1&to=5&ak=aeFrgGuMxVaPOcG9H7mb3ddvaYqasxHD',
      (err, baiduLocation) => {
        resolve(baiduLocation)
      }
    )
  })
}

// 获取城市的名称
function getCityName(baiduLocation) {
  return new Promise(function (resolve) {
    const lon = baiduLocation.result[0].x
    const lat = baiduLocation.result[0].y
    jsonp(
      'https://api.map.baidu.com/geocoder/v2/?ak=aeFrgGuMxVaPOcG9H7mb3ddvaYqasxHD&callback=renderReverse&location=' +
        lat +
        ',' +
        lon +
        '&output=json&pois=1',
      (err, res) => {
        const cityName = res.result.addressComponent.city
        const poiName = res.result.pois[0].name
        const location = { lon, lat, cityName, poiName }
        resolve(location)
      }
    )
  })
}
// 获取城市数据
function getCityInfo(location) {
  let userId = cookieStorage.getItem('user_id')
  let header = {}
  header['x-defined-appinfo'] = JSON.stringify({
    channel: cookieStorage.getItem('channel'),
    openId: cookieStorage.getItem('openId') || ''
  })
  if (userId > 0) {
    location.customerID = userId
    header['userToken'] = cookieStorage.getItem('user_token') || ''
  }
  return axios.post(`${baseURL}/store/checkCity`, location, {
    headers: header
  })
}

// 小程序内嵌H5 获取城市信息用
function getSmallProgramCityInfo(wechatLocation) {
  let { longitude, latitude } = wechatLocation
  let userId = sessionStorage.getItem('user_id')
  let header = {}
  let params = {
    longitude: longitude,
    latitude: latitude
  }
  header['x-defined-appinfo'] = JSON.stringify({
    channel: sessionStorage.getItem('channel'),
    openId: sessionStorage.getItem('openId') || ''
  })
  if (userId > 0) {
    params.customerID = userId
    header['userToken'] = sessionStorage.getItem('user_token') || ''
  }
  return axios.post(`${baseURL}/store/checkCityForSmallProgram`, params, {
    headers: header
  })
}

export async function jsApiAuth() {
  await getWxJsApiAuth()
}
// 获取定位信息
export async function getLocation() {
  // await getWxJsApiAuth()
  const wechatLocation = await getWechatLocation()
  const baiduLocation = await getBaiduLocation(wechatLocation)
  const location = await getCityName(baiduLocation)
  const res = await getCityInfo(location)
  cookieStorage.setItem('city_id', -1)
  cookieStorage.setItem('city_name', -1)
  cookieStorage.setItem('store_id', -1)
  cookieStorage.setItem('store_name', '')
  cookieStorage.setItem('distance', -1)
  cookieStorage.setItem('storeCode', -1)
  if (res.data.code == 0) {
    if (res.data.data.cityCanService === 'Y') {
      let cityId = res.data.data.city.cityID
      let cityName = res.data.data.city.cityName
      cookieStorage.setItem('city_id', cityId)
      cookieStorage.setItem('city_name', cityName)
    }
    if (res.data.data.storeList && res.data.data.storeList.length > 0) {
      let storeId = res.data.data.storeList[0].storeID
      let storeName = res.data.data.storeList[0].storeName
      let distance = res.data.data.storeList[0].distance
      let storeCode = res.data.data.storeList[0].storeNumber
      cookieStorage.setItem('store_id', storeId)
      cookieStorage.setItem('store_name', storeName)
      cookieStorage.setItem('distance', distance)
      cookieStorage.setItem('storeCode', storeCode)
    }
  }
}

// 小程序获取服务 坐标系转换 地址逆解析
export async function getSmallProgramLocation() {
  let wechatLocation = {
    latitude: '',
    longitude: ''
  }
  let cityId = -1
  let storeId = -1
  let cityName = ''
  let storeName = ''
  let lon = wechatLocation.longitude
  let lat = wechatLocation.latitude
  let poiName = ''
  let distance = -1
  if (window.appEnv == 'miniProgram') {
    let curLocation = JSON.parse(sessionStorage.getItem('curLocation'))
    if (Object.keys(curLocation).length > 0) {
      wechatLocation = {
        latitude: curLocation.lat,
        longitude: curLocation.lon
      }
    } else {
      Toast('未开启获取定位权限，请手动输入要选择的门店名称')
      return {
        cityId,
        storeId,
        cityName,
        storeName,
        lon,
        lat,
        poiName,
        distance
      }
    }
  } else {
    await getWxJsApiAuth()
    wechatLocation = await getWechatLocation()
  }

  lon = wechatLocation.longitude
  lat = wechatLocation.latitude

  const res = await getSmallProgramCityInfo(wechatLocation)
  if (res.data.code == 0) {
    if (res.data.data.cityCanService === 'Y') {
      cityId = res.data.data.city.cityID
      cityName = res.data.data.city.cityName
      poiName = res.data.data.h5PoisName
      if (res.data.data.storeList && res.data.data.storeList.length > 0) {
        storeId = res.data.data.storeList[0].storeID
        storeName = res.data.data.storeList[0].storeName
        distance = res.data.data.storeList[0].distance
      }
    }
  } else {
    Toast(res.data.msg)
  }
  // return {cityId,storeId}
  return { cityId, storeId, cityName, storeName, lon, lat, poiName, distance }
}
// 判断是否登录
export async function getIsBindPhone() {
  await getWxJsApiAuth()
  return new Promise((resolve) => {
    let openId = cookieStorage.getItem('openId')
    let header = {}
    header['x-defined-appinfo'] = JSON.stringify({
      channel: 'wx',
      openId: cookieStorage.getItem('openId') || ''
    })
    header['userToken'] = ''
    axios
      .post(`${baseURL}/customer/isBindPhone`, { openId }, { headers: header })
      .then((res) => {
        if (res.data.code == 0) {
          let { userID, userToken, phoneNumber } = res.data.data
          cookieStorage.setItem('user_id', userID)
          cookieStorage.setItem('user_token', userToken)
          cookieStorage.setItem('user_status', 1)
          cookieStorage.setItem('phone', phoneNumber)
        } else {
          cookieStorage.setItem('user_id', '')
          cookieStorage.setItem('user_token', '')
          cookieStorage.setItem('user_status', '')
          cookieStorage.setItem('phone', '')
        }
        resolve()
      })
  })
}
