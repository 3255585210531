import Vue from 'vue'
import Vuex from 'vuex'
import invite from './modules/invite'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isShowShareMask: false,
    cityID: '',
    storeID: '',
    shareWay: 1,
    isMiniPage: false,
    nearbyStoreList:[],
  },
  mutations: {
    IS_SHOW_SHARE_MASK(state, bool) {
      state.isShowShareMask = bool
    },
    IS_MINI_PAGE(state, bool) {
      state.isMiniPage = bool
    },
    SET_CITY_ID(state, cityID) {
      state.cityID = cityID
    },
    SET_STORE_ID(state, storeID) {
      state.storeID = storeID
    },
    SHARE_WAY(state, shareWay) {
      state.shareWay = shareWay
    },
  },
  modules: {
    invite,
  }
})

export default store
